import { Icon } from '@chakra-ui/icon';
const BriefCase = props => <Icon {...props} data-sentry-element="Icon" data-sentry-component="BriefCase" data-sentry-source-file="BriefCase.tsx">
    <svg width="24" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="BriefCase.tsx">
      <g clipPath="url(#clip0)" data-sentry-element="g" data-sentry-source-file="BriefCase.tsx">
        <path d="M5.12134 3.21214V0.666687H10.2122V3.21214" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square" fill="none" data-sentry-element="path" data-sentry-source-file="BriefCase.tsx" />
        <path d="M5.75766 9.5758H0.666748V3.21216H14.6667V9.5758H9.57584" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square" fill="none" data-sentry-element="path" data-sentry-source-file="BriefCase.tsx" />
        <path d="M14.0305 11.4848V14.6667H1.30322V11.4848" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square" fill="none" data-sentry-element="path" data-sentry-source-file="BriefCase.tsx" />
        <path d="M9.57575 8.30305H5.75757V10.8485H9.57575V8.30305Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square" fill="none" data-sentry-element="path" data-sentry-source-file="BriefCase.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="BriefCase.tsx">
        <clipPath id="clip0" data-sentry-element="clipPath" data-sentry-source-file="BriefCase.tsx">
          <rect width="16" height="16" fill="white" data-sentry-element="rect" data-sentry-source-file="BriefCase.tsx" />
        </clipPath>
      </defs>
    </svg>
  </Icon>;
BriefCase.displayName = 'BriefCaseIcon';
export default BriefCase;