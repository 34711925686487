import { CbDateRangePicker } from 'ui/components/CbDateRangePicker';
import FilterOutline from 'ui/components/Icons/FilterOutline';
interface DateRangePickerProps {
  setValue: (value: Date[]) => void;
  value: Date[];
  isDisabled?: boolean;
}
export const DateRangePicker = ({
  setValue,
  value,
  isDisabled
}: DateRangePickerProps) => {
  const onClear = () => {
    setValue([]);
  };
  return <CbDateRangePicker onChange={setValue} onClear={onClear} targetIcon={<FilterOutline color={'spyro.500'} />} showOptions={true} isDisabled={isDisabled} targetProps={{
    placeholder: 'Filter by Date ',
    borderColor: 'gray.400',
    height: '38px',
    background: 'white'
  }} values={value} data-sentry-element="CbDateRangePicker" data-sentry-component="DateRangePicker" data-sentry-source-file="DateRangePicker.tsx" />;
};