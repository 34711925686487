import { useToast, UseToastOptions } from '@chakra-ui/toast';
import { createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import { ArcadeAuth } from 'ui/types';
export interface AppContextProps {
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  setToast: (options: UseToastOptions) => void;
  identifyUserForAppcues: (auth: ArcadeAuth) => void;
}
interface MPAppContextProviderProps {
  children?: ReactNode;
}
export const AppContext = createContext({} as AppContextProps);
export const MPAppContextProvider: FC<MPAppContextProviderProps> = ({
  children
}) => {
  const toast = useToast();
  const [title, setTitle] = useState('Arcade');
  const setToast = (options: UseToastOptions): void => {
    // Prevent duplicate toasts
    if (!options.id || !toast.isActive(options.id)) toast({
      position: 'bottom-right',
      isClosable: true,
      ...options
    });
  };
  const identifyUserForAppcues = (auth: ArcadeAuth): void => {
    window.Appcues.identify(auth.id, {
      id: auth.id,
      email: auth.email,
      createdAt: auth.createdAt,
      profileImage: auth.profileImage,
      locked: auth.locked,
      role: auth.role,
      firstName: auth.firstName,
      lastName: auth.lastName,
      fullName: auth.fullName,
      entityId: auth.entityId,
      entityName: auth.entityName
    });
    window.Appcues.group(auth.entityId, {
      entityName: auth.entityName
    });
  };
  return <AppContext.Provider value={{
    title,
    setTitle,
    setToast,
    identifyUserForAppcues
  }} data-sentry-element="unknown" data-sentry-component="MPAppContextProvider" data-sentry-source-file="MPAppContextProvider.tsx">
      {children}
    </AppContext.Provider>;
};
export default MPAppContextProvider;