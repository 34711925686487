import { Icon } from '@chakra-ui/icon';
const CheckAll = props => <Icon viewBox="0 0 24 24" {...props} data-sentry-element="Icon" data-sentry-component="CheckAll" data-sentry-source-file="CheckAll.tsx">
    <g fill="none" data-sentry-element="g" data-sentry-source-file="CheckAll.tsx">
      <path d="M2 14L6 18L18 3" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="CheckAll.tsx" />
      <path d="M23 8H20" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="CheckAll.tsx" />
      <path d="M23 13H16" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="CheckAll.tsx" />
      <path d="M23 18H12" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="CheckAll.tsx" />
    </g>
  </Icon>;
CheckAll.displayName = 'CheckAllIcon';
export default CheckAll;