import { Icon } from '@chakra-ui/icon';
const OpenArcadeChest = props => <Icon viewBox="0 0 256 224" {...props} data-sentry-element="Icon" data-sentry-component="OpenArcadeChest" data-sentry-source-file="OpenArcadeChest.tsx">
    <path d="M4.43091 59.0768H250.831L217.108 116.677H38.8924L4.43091 59.0768Z" fill="url(#paint0_radial)" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M1.96936 12.3077C1.96936 5.51034 7.4797 0 14.2771 0H241.723C248.521 0 254.031 5.51034 254.031 12.3077V60.5538C254.031 61.6414 253.149 62.5231 252.062 62.5231H3.93859C2.85101 62.5231 1.96936 61.6414 1.96936 60.5538V12.3077Z" fill="#E5AC0C" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M29.0463 7.87692C29.0463 3.52662 32.5729 0 36.9232 0H219.077C223.427 0 226.954 3.52662 226.954 7.87692V41.3539C226.954 45.7042 223.427 49.2308 219.077 49.2308H36.9232C32.5729 49.2308 29.0463 45.7042 29.0463 41.3538V7.87692Z" fill="#B26752" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path fillRule="evenodd" clipRule="evenodd" d="M224.492 24.6155H31.5078V19.6924H224.492V24.6155Z" fill="url(#paint1_radial)" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M17.5236 10.6667H13.1423C13.0405 10.6667 12.9464 10.721 12.8955 10.8092L10.7048 14.6035C10.6539 14.6917 10.6539 14.8003 10.7048 14.8885L12.8955 18.6828C12.9464 18.771 13.0405 18.8253 13.1423 18.8253H17.5236C17.6254 18.8253 17.7195 18.771 17.7704 18.6828L19.961 14.8885C20.0119 14.8003 20.0119 14.6917 19.961 14.6035L17.7704 10.8092C17.7195 10.721 17.6254 10.6667 17.5236 10.6667Z" fill="#FFD45D" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M13.9344 17.1731L12.5352 14.7497H10.6672C10.6672 14.7989 10.68 14.8481 10.7054 14.8922L12.8961 18.6865C12.9215 18.7306 12.9578 18.7662 13.0004 18.7908L13.9344 17.1731Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M13.9338 12.3247L12.9998 10.707C12.9572 10.7316 12.9209 10.7672 12.8955 10.8113L10.7048 14.6057C10.6793 14.6497 10.6666 14.6989 10.6666 14.7481H12.5346L13.9338 12.3247Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M13.9345 12.3261H16.7328L17.6668 10.7084C17.6242 10.6838 17.5752 10.6702 17.5243 10.6702H13.143C13.0921 10.6702 13.0431 10.6838 13.0005 10.7084L13.9345 12.3261Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M16.733 12.3264L18.1322 14.7498H20.0002C20.0002 14.7006 19.9874 14.6514 19.962 14.6073L17.7713 10.813C17.7459 10.7689 17.7096 10.7333 17.667 10.7087L16.733 12.3264Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M16.7327 12.3263H13.9343L12.5352 14.7497L13.9343 17.1731H16.7327L18.1318 14.7497L16.7327 12.3263Z" fill="#FFCA14" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M241.524 10.6667H237.142C237.04 10.6667 236.946 10.721 236.895 10.8092L234.705 14.6035C234.654 14.6917 234.654 14.8003 234.705 14.8885L236.895 18.6828C236.946 18.771 237.04 18.8253 237.142 18.8253H241.524C241.625 18.8253 241.719 18.771 241.77 18.6828L243.961 14.8885C244.012 14.8003 244.012 14.6917 243.961 14.6035L241.77 10.8092C241.719 10.721 241.625 10.6667 241.524 10.6667Z" fill="#FFD45D" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M237.934 17.1731L236.535 14.7497H234.667C234.667 14.7989 234.68 14.8481 234.705 14.8922L236.896 18.6865C236.922 18.7306 236.958 18.7662 237 18.7908L237.934 17.1731Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M237.934 12.3247L237 10.707C236.957 10.7316 236.921 10.7672 236.895 10.8113L234.705 14.6057C234.679 14.6497 234.667 14.6989 234.667 14.7481H236.535L237.934 12.3247Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M237.934 12.3261H240.733L241.667 10.7084C241.624 10.6838 241.575 10.6702 241.524 10.6702H237.143C237.092 10.6702 237.043 10.6838 237 10.7084L237.934 12.3261Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M240.733 12.3264L242.132 14.7498H244C244 14.7006 243.987 14.6514 243.962 14.6073L241.771 10.813C241.746 10.7689 241.71 10.7333 241.667 10.7087L240.733 12.3264Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M240.733 12.3263H237.934L236.535 14.7497L237.934 17.1731H240.733L242.132 14.7497L240.733 12.3263Z" fill="#FFCA14" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M188.19 52H183.809C183.707 52 183.613 52.0543 183.562 52.1425L181.372 55.9368C181.321 56.025 181.321 56.1336 181.372 56.2218L183.562 60.0161C183.613 60.1043 183.707 60.1586 183.809 60.1586H188.19C188.292 60.1586 188.386 60.1043 188.437 60.0161L190.628 56.2218C190.679 56.1336 190.679 56.025 190.628 55.9368L188.437 52.1425C188.386 52.0543 188.292 52 188.19 52Z" fill="#FFD45D" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M184.601 58.5065L183.202 56.083H181.334C181.334 56.1322 181.347 56.1814 181.372 56.2255L183.563 60.0198C183.588 60.0639 183.625 60.0996 183.667 60.1242L184.601 58.5065Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M184.601 53.658L183.667 52.0403C183.624 52.0649 183.588 52.1006 183.562 52.1446L181.372 55.939C181.346 55.9831 181.333 56.0323 181.333 56.0815H183.201L184.601 53.658Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M184.601 53.6595H187.4L188.334 52.0418C188.291 52.0172 188.242 52.0036 188.191 52.0036H183.81C183.759 52.0036 183.71 52.0172 183.667 52.0418L184.601 53.6595Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M187.4 53.6597L188.799 56.0831H190.667C190.667 56.0339 190.654 55.9847 190.629 55.9406L188.438 52.1463C188.413 52.1022 188.376 52.0666 188.334 52.042L187.4 53.6597Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M187.399 53.6596H184.601L183.202 56.083L184.601 58.5065H187.399L188.799 56.083L187.399 53.6596Z" fill="#FFCA14" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M70.8569 52H66.4757C66.3738 52 66.2797 52.0543 66.2288 52.1425L64.0382 55.9368C63.9873 56.025 63.9873 56.1336 64.0382 56.2218L66.2288 60.0161C66.2797 60.1043 66.3738 60.1586 66.4757 60.1586H70.8569C70.9588 60.1586 71.0529 60.1043 71.1038 60.0161L73.2944 56.2218C73.3453 56.1336 73.3453 56.025 73.2944 55.9368L71.1038 52.1425C71.0529 52.0543 70.9588 52 70.8569 52Z" fill="#FFD45D" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M67.2678 58.5065L65.8686 56.083H64.0006C64.0006 56.1322 64.0133 56.1814 64.0388 56.2255L66.2294 60.0198C66.2549 60.0639 66.2911 60.0996 66.3338 60.1242L67.2678 58.5065Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M67.2671 53.658L66.3332 52.0403C66.2905 52.0649 66.2543 52.1006 66.2288 52.1446L64.0382 55.939C64.0127 55.9831 64 56.0323 64 56.0815H65.868L67.2671 53.658Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M67.2679 53.6595H70.0662L71.0002 52.0418C70.9575 52.0172 70.9086 52.0036 70.8577 52.0036H66.4764C66.4255 52.0036 66.3765 52.0172 66.3339 52.0418L67.2679 53.6595Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M70.0664 53.6597L71.4656 56.0831H73.3335C73.3335 56.0339 73.3208 55.9847 73.2954 55.9406L71.1047 52.1463C71.0793 52.1022 71.043 52.0666 71.0004 52.042L70.0664 53.6597Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M70.0661 53.6596H67.2677L65.8685 56.083L67.2677 58.5065H70.0661L71.4652 56.083L70.0661 53.6596Z" fill="#FFCA14" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M9.84629 24C26.1599 24 39.3848 37.2248 39.3848 53.5385L39.3848 59.4462C39.3848 61.6213 37.6215 63.3846 35.4463 63.3846L1.96938 63.3846C0.881805 63.3846 0.000150554 62.503 0.000150506 61.4154L0.000149043 27.9385C0.000148948 25.7633 1.76346 24 3.93861 24L9.84629 24Z" fill="#FFC269" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M12.9338 24C27.2129 24 40.0928 42.8623 36.4923 56.5258C35.6508 59.7182 31.7592 60.7292 29.5841 60.7292C20.7091 60.7292 13.3341 60.7292 0.0161427 60.7292C0.0161427 56 0.0161427 28.2818 0.0161427 28.2818C-0.185268 25.9799 1.62898 24 3.93961 24L12.9338 24Z" fill="#FFD64F" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M246.154 24C229.84 24 216.615 37.2248 216.615 53.5385L216.615 59.4462C216.615 61.6213 218.379 63.3846 220.554 63.3846L254.031 63.3846C255.118 63.3846 256 62.503 256 61.4154L256 27.9385C256 25.7633 254.237 24 252.061 24L246.154 24Z" fill="#FFC269" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M246.154 24C229.84 24 216.615 37.2248 216.615 53.5385L216.615 58.8958C216.615 61.0625 216.881 60.7368 219.794 60.7194C231.104 60.7194 240.118 60.6969 246.402 60.7194C249.035 60.7288 251.21 58.8067 251.35 56.1766C251.862 46.5942 252.061 27.262 252.061 24L246.154 24Z" fill="#FFD64F" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <g filter="url(#filter0_di)" data-sentry-element="g" data-sentry-source-file="OpenArcadeChest.tsx">
      <path d="M150.35 63.5072C147.193 63.5072 144.639 60.9526 144.639 57.7965V49.2306C144.639 39.7835 136.954 32.0985 127.507 32.0985C118.06 32.0985 110.375 39.7835 110.375 49.2306V57.7965C110.375 60.9533 107.821 63.5072 104.664 63.5072C101.508 63.5072 98.9537 60.9526 98.9537 57.7965V49.2306C98.9537 33.4871 111.764 20.6771 127.507 20.6771C143.251 20.6771 156.061 33.4871 156.061 49.2306V57.7965C156.061 60.9533 153.507 63.5072 150.35 63.5072Z" fill="url(#paint2_linear)" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    </g>
    <path d="M2.00167 100.253C1.98426 100.038 2.15403 99.8535 2.3697 99.8535H253.631C253.846 99.8535 254.016 100.038 253.999 100.253L244.295 220.096C244.233 220.863 243.592 221.454 242.823 221.454H13.1777C12.4082 221.454 11.7677 220.863 11.7056 220.096L2.00167 100.253Z" fill="#E5AC0C" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M25.7371 115.244C25.6638 114.382 26.3437 113.642 27.2087 113.642H228.793C229.658 113.642 230.338 114.382 230.265 115.244L222.516 206.321C222.451 207.085 221.811 207.673 221.044 207.673H34.9574C34.1903 207.673 33.5509 207.085 33.4858 206.321L25.7371 115.244Z" fill="#B26752" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M70.8569 102.667H66.4756C66.3738 102.667 66.2797 102.721 66.2288 102.809L64.0382 106.603C63.9873 106.692 63.9873 106.8 64.0382 106.889L66.2288 110.683C66.2797 110.771 66.3738 110.825 66.4756 110.825H70.8569C70.9588 110.825 71.0529 110.771 71.1038 110.683L73.2944 106.889C73.3453 106.8 73.3453 106.692 73.2944 106.603L71.1038 102.809C71.0529 102.721 70.9588 102.667 70.8569 102.667Z" fill="#FFD45D" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M67.2677 109.173L65.8686 106.75H64.0006C64.0006 106.799 64.0133 106.848 64.0388 106.892L66.2294 110.687C66.2549 110.731 66.2911 110.766 66.3338 110.791L67.2677 109.173Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M67.2671 104.325L66.3331 102.707C66.2905 102.732 66.2543 102.767 66.2288 102.811L64.0382 106.606C64.0127 106.65 64 106.699 64 106.748H65.868L67.2671 104.325Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M67.2679 104.326H70.0662L71.0002 102.708C70.9575 102.684 70.9086 102.67 70.8577 102.67H66.4764C66.4255 102.67 66.3765 102.684 66.3339 102.708L67.2679 104.326Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M70.0664 104.326L71.4656 106.75H73.3335C73.3335 106.701 73.3208 106.651 73.2954 106.607L71.1047 102.813C71.0793 102.769 71.043 102.733 71.0004 102.709L70.0664 104.326Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M70.066 104.326H67.2677L65.8685 106.75L67.2677 109.173H70.066L71.4652 106.75L70.066 104.326Z" fill="#FFCA14" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M188.19 102.667H183.809C183.707 102.667 183.613 102.721 183.562 102.809L181.371 106.603C181.321 106.692 181.321 106.8 181.371 106.889L183.562 110.683C183.613 110.771 183.707 110.825 183.809 110.825H188.19C188.292 110.825 188.386 110.771 188.437 110.683L190.628 106.889C190.679 106.8 190.679 106.692 190.628 106.603L188.437 102.809C188.386 102.721 188.292 102.667 188.19 102.667Z" fill="#FFD45D" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M184.601 109.173L183.202 106.75H181.334C181.334 106.799 181.347 106.848 181.372 106.892L183.563 110.687C183.588 110.731 183.624 110.766 183.667 110.791L184.601 109.173Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M184.6 104.325L183.666 102.707C183.624 102.732 183.588 102.767 183.562 102.811L181.371 106.606C181.346 106.65 181.333 106.699 181.333 106.748H183.201L184.6 104.325Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M184.601 104.326H187.399L188.333 102.708C188.291 102.684 188.242 102.67 188.191 102.67H183.81C183.759 102.67 183.71 102.684 183.667 102.708L184.601 104.326Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M187.4 104.326L188.799 106.75H190.667C190.667 106.701 190.654 106.651 190.629 106.607L188.438 102.813C188.413 102.769 188.376 102.733 188.334 102.709L187.4 104.326Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M187.399 104.326H184.601L183.202 106.75L184.601 109.173H187.399L188.798 106.75L187.399 104.326Z" fill="#FFCA14" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M238.857 156H234.476C234.374 156 234.28 156.054 234.229 156.143L232.038 159.937C231.987 160.025 231.987 160.134 232.038 160.222L234.229 164.016C234.28 164.104 234.374 164.159 234.476 164.159H238.857C238.959 164.159 239.053 164.104 239.104 164.016L241.294 160.222C241.345 160.134 241.345 160.025 241.294 159.937L239.104 156.143C239.053 156.054 238.959 156 238.857 156Z" fill="#FFD45D" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M235.268 162.506L233.869 160.083H232.001C232.001 160.132 232.013 160.181 232.039 160.226L234.229 164.02C234.255 164.064 234.291 164.1 234.334 164.124L235.268 162.506Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M235.267 157.658L234.333 156.04C234.291 156.065 234.254 156.101 234.229 156.145L232.038 159.939C232.013 159.983 232 160.032 232 160.081H233.868L235.267 157.658Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M235.268 157.659H238.066L239 156.042C238.958 156.017 238.909 156.004 238.858 156.004H234.476C234.425 156.004 234.376 156.017 234.334 156.042L235.268 157.659Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M238.066 157.66L239.466 160.083H241.334C241.334 160.034 241.321 159.985 241.295 159.941L239.105 156.146C239.079 156.102 239.043 156.067 239 156.042L238.066 157.66Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M238.066 157.66H235.268L233.869 160.083L235.268 162.506H238.066L239.465 160.083L238.066 157.66Z" fill="#FFCA14" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M20.1902 156H15.8089C15.7071 156 15.613 156.054 15.5621 156.143L13.3714 159.937C13.3205 160.025 13.3205 160.134 13.3714 160.222L15.5621 164.016C15.613 164.104 15.7071 164.159 15.8089 164.159H20.1902C20.292 164.159 20.3861 164.104 20.437 164.016L22.6277 160.222C22.6786 160.134 22.6786 160.025 22.6277 159.937L20.437 156.143C20.3861 156.054 20.292 156 20.1902 156Z" fill="#FFD45D" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M16.601 162.506L15.2018 160.083H13.3339C13.3339 160.132 13.3466 160.181 13.372 160.226L15.5627 164.02C15.5882 164.064 15.6244 164.1 15.667 164.124L16.601 162.506Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M16.6004 157.658L15.6664 156.04C15.6238 156.065 15.5875 156.101 15.5621 156.145L13.3714 159.939C13.346 159.983 13.3333 160.032 13.3333 160.081H15.2012L16.6004 157.658Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M16.6011 157.659H19.3994L20.3334 156.042C20.2908 156.017 20.2418 156.004 20.1909 156.004H15.8096C15.7587 156.004 15.7097 156.017 15.6671 156.042L16.6011 157.659Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M19.3997 157.66L20.7988 160.083H22.6668C22.6668 160.034 22.6541 159.985 22.6286 159.941L20.438 156.146C20.4125 156.102 20.3763 156.067 20.3336 156.042L19.3997 157.66Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M19.3993 157.66H16.6009L15.2018 160.083L16.6009 162.506H19.3993L20.7985 160.083L19.3993 157.66Z" fill="#FFCA14" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M86.8569 210.667H82.4757C82.3738 210.667 82.2797 210.721 82.2288 210.809L80.0382 214.603C79.9873 214.692 79.9873 214.8 80.0382 214.888L82.2288 218.683C82.2797 218.771 82.3738 218.825 82.4757 218.825H86.8569C86.9588 218.825 87.0529 218.771 87.1038 218.683L89.2944 214.888C89.3453 214.8 89.3453 214.692 89.2944 214.603L87.1038 210.809C87.0529 210.721 86.9588 210.667 86.8569 210.667Z" fill="#FFD45D" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M83.2678 217.173L81.8686 214.75H80.0006C80.0006 214.799 80.0133 214.848 80.0388 214.892L82.2294 218.686C82.2549 218.731 82.2911 218.766 82.3338 218.791L83.2678 217.173Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M83.2671 212.325L82.3332 210.707C82.2905 210.732 82.2543 210.767 82.2288 210.811L80.0382 214.606C80.0127 214.65 80 214.699 80 214.748H81.868L83.2671 212.325Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M83.2679 212.326H86.0662L87.0002 210.708C86.9575 210.684 86.9086 210.67 86.8577 210.67H82.4764C82.4255 210.67 82.3765 210.684 82.3339 210.708L83.2679 212.326Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M86.0664 212.326L87.4656 214.75H89.3335C89.3335 214.701 89.3208 214.651 89.2954 214.607L87.1047 210.813C87.0793 210.769 87.043 210.733 87.0004 210.709L86.0664 212.326Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M86.0661 212.326H83.2677L81.8685 214.75L83.2677 217.173H86.0661L87.4652 214.75L86.0661 212.326Z" fill="#FFCA14" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M172.19 210.667H167.809C167.707 210.667 167.613 210.721 167.562 210.809L165.371 214.603C165.321 214.692 165.321 214.8 165.371 214.888L167.562 218.683C167.613 218.771 167.707 218.825 167.809 218.825H172.19C172.292 218.825 172.386 218.771 172.437 218.683L174.628 214.888C174.679 214.8 174.679 214.692 174.628 214.603L172.437 210.809C172.386 210.721 172.292 210.667 172.19 210.667Z" fill="#FFD45D" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M168.601 217.173L167.202 214.75H165.334C165.334 214.799 165.347 214.848 165.372 214.892L167.563 218.686C167.588 218.731 167.624 218.766 167.667 218.791L168.601 217.173Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M168.6 212.325L167.666 210.707C167.624 210.732 167.588 210.767 167.562 210.811L165.371 214.606C165.346 214.65 165.333 214.699 165.333 214.748H167.201L168.6 212.325Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M168.601 212.326H171.399L172.333 210.708C172.291 210.684 172.242 210.67 172.191 210.67H167.81C167.759 210.67 167.71 210.684 167.667 210.708L168.601 212.326Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M171.4 212.326L172.799 214.75H174.667C174.667 214.701 174.654 214.651 174.629 214.607L172.438 210.813C172.413 210.769 172.376 210.733 172.334 210.709L171.4 212.326Z" fill="#FFDC02" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M171.399 212.326H168.601L167.202 214.75L168.601 217.173H171.399L172.798 214.75L171.399 212.326Z" fill="#FFCA14" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M9.84632 137.433C26.16 137.433 39.3848 124.208 39.3848 107.895L39.3848 101.987C39.3848 99.812 37.6215 98.0487 35.4463 98.0487L2.13587 98.0487C0.985411 98.0487 0.0799388 99.0307 0.173106 100.177L2.90606 133.814C3.0722 135.859 4.78011 137.433 6.83159 137.433L9.84632 137.433Z" fill="#FFC269" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M39.3848 101.987C39.3848 99.8115 37.6215 98.0481 35.4463 98.0481L2.13587 98.0481C0.98541 98.0481 0.0799386 99.0302 0.173106 100.177L2.90606 133.813C22.1851 137.033 39.3848 120.116 39.3848 101.987Z" fill="#FFD64F" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M246.154 137.433C229.841 137.433 216.616 124.208 216.616 107.895L216.616 101.987C216.616 99.812 218.379 98.0486 220.554 98.0486L253.865 98.0486C255.015 98.0486 255.921 99.0307 255.827 100.177L253.095 133.814C252.928 135.858 251.22 137.433 249.169 137.433L246.154 137.433Z" fill="#FFC269" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M216.615 107.895L216.615 101.988C216.615 99.8125 218.379 98.0492 220.554 98.0492L250.776 98.0492C249.867 106.929 248.885 128.807 247.862 132.45C246.838 136.092 237 135.045 232.231 133.721C227.463 132.397 216.366 122.05 216.615 107.895Z" fill="#FFD64F" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M19.6926 182.233C36.0063 182.233 49.2311 195.458 49.2311 211.772L49.2311 217.679C49.2311 219.854 47.4678 221.618 45.2926 221.618L11.6509 221.618C10.6299 221.618 9.77817 220.837 9.68917 219.82L6.77496 186.515C6.57355 184.213 8.38779 182.233 10.6984 182.233L19.6926 182.233Z" fill="#FFC269" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M19.6941 182.233C40.215 182.233 44.3359 197.956 44.3359 215.882C44.3359 218.057 42.4156 218.83 40.2404 218.83C34.0877 219.215 24.7529 218.83 9.57527 218.83L6.77642 186.515C6.57501 184.213 8.38926 182.233 10.6999 182.233L19.6941 182.233Z" fill="#FFD64F" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M236.308 182.233C219.994 182.233 206.769 195.458 206.769 211.772L206.769 217.679C206.769 219.854 208.533 221.618 210.708 221.618L244.35 221.618C245.371 221.618 246.222 220.837 246.311 219.82L249.226 186.515C249.427 184.213 247.613 182.233 245.302 182.233L236.308 182.233Z" fill="#FFC269" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <path d="M236.307 182.233C217.957 182.602 205.177 197.091 206.733 216.542C206.733 218.752 208.725 219.032 212.926 219.032C222.392 219.102 232.118 219.264 239.377 219.264C240.398 219.264 241.249 218.484 241.338 217.467C241.98 210.137 242.681 201.29 244.353 182.233L236.307 182.233Z" fill="#FFD64F" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <line x1="31.5078" y1="177.176" x2="224.492" y2="177.176" stroke="url(#paint3_radial)" strokeWidth="0.923077" data-sentry-element="line" data-sentry-source-file="OpenArcadeChest.tsx" />
    <line x1="31.5078" y1="137.792" x2="224.492" y2="137.792" stroke="url(#paint4_radial)" strokeWidth="0.923077" data-sentry-element="line" data-sentry-source-file="OpenArcadeChest.tsx" />
    <g filter="url(#filter1_di)" data-sentry-element="g" data-sentry-source-file="OpenArcadeChest.tsx">
      <path d="M150.696 92.472H105.01C101.855 92.472 99.2988 95.0286 99.2988 98.1827V109.604C99.2988 125.374 112.083 138.158 127.852 138.158C143.622 138.158 156.406 125.374 156.406 109.604V98.1827C156.407 95.0286 153.85 92.472 150.696 92.472Z" fill="#FFF482" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    </g>
    <path d="M136.9 117.657L137.083 117.55L137.083 117.338L137.083 107L137.083 106.788L136.9 106.681L128.038 101.511L127.852 101.403L127.666 101.511L118.804 106.681L118.621 106.788L118.621 107L118.621 117.338L118.621 117.55L118.804 117.657L127.666 122.826L127.852 122.935L128.038 122.826L136.9 117.657Z" fill="#6F5F39" stroke="url(#paint5_linear)" strokeWidth="0.738462" data-sentry-element="path" data-sentry-source-file="OpenArcadeChest.tsx" />
    <defs data-sentry-element="defs" data-sentry-source-file="OpenArcadeChest.tsx">
      <filter id="filter0_di" x="98.5845" y="20.6771" width="57.8455" height="43.5685" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB" data-sentry-element="filter" data-sentry-source-file="OpenArcadeChest.tsx">
        <feFlood floodOpacity="0" result="BackgroundImageFix" data-sentry-element="feFlood" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" data-sentry-element="feColorMatrix" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feOffset dy="0.369231" data-sentry-element="feOffset" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feGaussianBlur stdDeviation="0.184615" data-sentry-element="feGaussianBlur" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" data-sentry-element="feColorMatrix" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" data-sentry-element="feBlend" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" data-sentry-element="feBlend" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" data-sentry-element="feColorMatrix" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feOffset dx="-0.923077" dy="-0.496615" data-sentry-element="feOffset" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" data-sentry-element="feComposite" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.760784 0 0 0 0 0.411765 0 0 0 1 0" data-sentry-element="feColorMatrix" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" data-sentry-element="feBlend" data-sentry-source-file="OpenArcadeChest.tsx" />
      </filter>
      <filter id="filter1_di" x="98.9296" y="92.472" width="57.8455" height="46.4241" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB" data-sentry-element="filter" data-sentry-source-file="OpenArcadeChest.tsx">
        <feFlood floodOpacity="0" result="BackgroundImageFix" data-sentry-element="feFlood" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" data-sentry-element="feColorMatrix" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feOffset dy="0.369231" data-sentry-element="feOffset" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feGaussianBlur stdDeviation="0.184615" data-sentry-element="feGaussianBlur" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" data-sentry-element="feColorMatrix" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" data-sentry-element="feBlend" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" data-sentry-element="feBlend" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" data-sentry-element="feColorMatrix" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feOffset dx="-0.923077" dy="-0.496615" data-sentry-element="feOffset" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" data-sentry-element="feComposite" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.760784 0 0 0 0 0.411765 0 0 0 1 0" data-sentry-element="feColorMatrix" data-sentry-source-file="OpenArcadeChest.tsx" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" data-sentry-element="feBlend" data-sentry-source-file="OpenArcadeChest.tsx" />
      </filter>
      <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.508 84.1845) rotate(-90) scale(100.923 431.726)" data-sentry-element="radialGradient" data-sentry-source-file="OpenArcadeChest.tsx">
        <stop stopColor="#8B5040" data-sentry-element="stop" data-sentry-source-file="OpenArcadeChest.tsx" />
        <stop offset="1" data-sentry-element="stop" data-sentry-source-file="OpenArcadeChest.tsx" />
      </radialGradient>
      <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(129.969 3.28447) rotate(-179.998) scale(111.015 471445)" data-sentry-element="radialGradient" data-sentry-source-file="OpenArcadeChest.tsx">
        <stop stopColor="#6E3F33" data-sentry-element="stop" data-sentry-source-file="OpenArcadeChest.tsx" />
        <stop offset="1" stopColor="#6E3F33" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="OpenArcadeChest.tsx" />
      </radialGradient>
      <linearGradient id="paint2_linear" x1="127.507" y1="20.6771" x2="127.507" y2="77.7841" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="OpenArcadeChest.tsx">
        <stop stopColor="#FFF482" data-sentry-element="stop" data-sentry-source-file="OpenArcadeChest.tsx" />
        <stop offset="1" stopColor="#FFBE82" data-sentry-element="stop" data-sentry-source-file="OpenArcadeChest.tsx" />
      </linearGradient>
      <radialGradient id="paint3_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(129.969 173.64) rotate(-180) scale(111.015 88396)" data-sentry-element="radialGradient" data-sentry-source-file="OpenArcadeChest.tsx">
        <stop stopColor="#6E3F33" data-sentry-element="stop" data-sentry-source-file="OpenArcadeChest.tsx" />
        <stop offset="1" stopColor="#6E3F33" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="OpenArcadeChest.tsx" />
      </radialGradient>
      <radialGradient id="paint4_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(129.969 134.255) rotate(-180) scale(111.015 88396)" data-sentry-element="radialGradient" data-sentry-source-file="OpenArcadeChest.tsx">
        <stop stopColor="#6E3F33" data-sentry-element="stop" data-sentry-source-file="OpenArcadeChest.tsx" />
        <stop offset="1" stopColor="#6E3F33" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="OpenArcadeChest.tsx" />
      </radialGradient>
      <linearGradient id="paint5_linear" x1="136.714" y1="112.169" x2="118.99" y2="112.169" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="OpenArcadeChest.tsx">
        <stop stopColor="#FFC269" data-sentry-element="stop" data-sentry-source-file="OpenArcadeChest.tsx" />
        <stop offset="1" stopColor="#FFC269" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="OpenArcadeChest.tsx" />
      </linearGradient>
    </defs>
  </Icon>;
OpenArcadeChest.displayName = 'OpenArcadeChestIcon';
export default OpenArcadeChest;