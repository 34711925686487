import { FC, PropsWithChildren } from 'react';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import theme from '../../styles/theme';
export const CbThemeProvider: FC<PropsWithChildren> = ({
  children
}) => {
  return <ChakraProvider theme={theme} data-sentry-element="ChakraProvider" data-sentry-component="CbThemeProvider" data-sentry-source-file="CbThemeProvider.tsx">
      <CSSReset data-sentry-element="CSSReset" data-sentry-source-file="CbThemeProvider.tsx" />
      {children}
    </ChakraProvider>;
};