import { FC, ReactNode } from 'react';
import { Popover, PopoverTrigger, PopoverBody, PopoverContent, Tag, PopoverProps } from '@chakra-ui/react';
export interface CbTooltipProps extends PopoverProps {
  content: ReactNode;
  width?: string;
  backgroundColor?: string;
  color?: string;
  children: ReactNode;
}
export const CbTooltip: FC<CbTooltipProps> = ({
  backgroundColor,
  children,
  color,
  content,
  width,
  ...props
}) => <Popover trigger="hover" {...props} data-sentry-element="Popover" data-sentry-component="CbTooltip" data-sentry-source-file="CbTooltip.tsx">
    <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="CbTooltip.tsx">
      <Tag backgroundColor="transparent" padding={0} data-sentry-element="Tag" data-sentry-source-file="CbTooltip.tsx">
        {children}
      </Tag>
    </PopoverTrigger>
    <PopoverContent backgroundColor={backgroundColor || 'cloud.800'} borderColor={backgroundColor || 'inherit'} maxWidth={width || '300px'} width="auto" color={color || 'cloud.100'} data-sentry-element="PopoverContent" data-sentry-source-file="CbTooltip.tsx">
      <PopoverBody data-sentry-element="PopoverBody" data-sentry-source-file="CbTooltip.tsx">{content}</PopoverBody>
    </PopoverContent>
  </Popover>;