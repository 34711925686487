export interface ArcadeAuth {
  authenticationToken: string;
  createdAt: string;
  email: string;
  endpoint: string;
  entityId: number;
  entityName: string;
  firstName: string;
  fullName: string;
  id: number;
  imageBlob: string;
  imageUrl: string;
  isArcade?: boolean;
  isTeams?: boolean;
  lastName: string;
  locked: boolean;
  profileImage: string;
  role: string;
  token: string;
}
export const AUTH_STORAGE_KEY = 'arcade.auth';