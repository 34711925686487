import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import { arcadeApiClient } from '../apiClients';
import { Role } from '../types';
export const RoleContext = createContext<Role | null>(null);
export const RoleProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [role, setRole] = useState(null);
  useEffect(() => {
    arcadeApiClient.get('/multi_platform/profile/role').then(res => setRole(res.data.role));
  }, []);
  return <RoleContext.Provider value={role} data-sentry-element="unknown" data-sentry-component="RoleProvider" data-sentry-source-file="RoleProvider.tsx">
      {role ? children : <Loading />}
    </RoleContext.Provider>;
};
function Loading() {
  return <Flex alignItems="center" height="100vh" justifyContent="center" width="100%" data-sentry-element="Flex" data-sentry-component="Loading" data-sentry-source-file="RoleProvider.tsx">
      <Spinner color="chonky.500" emptyColor="gray.200" size="lg" speed="0.65s" thickness="4px" data-sentry-element="Spinner" data-sentry-source-file="RoleProvider.tsx" />
    </Flex>;
}