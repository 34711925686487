import { FC } from 'react';
import { Avatar, AvatarBadge, Image, AvatarProps } from '@chakra-ui/react';

/*
  This component is a very thin wrapper over the default Chakra avatar component.

  One key thing to call out is that if you are wanting to provide a default height/width for the avatar (as we currently do in RecognitionReceivedCard) you should pass an `avatarSize` of "custom".

  This makes use of a piece of Chakra behaviour where it won't attempt to set the font-size on the inner label for unrecognised variants.

  This means that you can then pass whichever font size you want.
*/

export interface CbAvatarProps extends AvatarProps {
  avatarSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'custom';
  badgeUrl?: string;
  image?: string | null;
  name?: string;
}
export const CbAvatar: FC<CbAvatarProps> = ({
  avatarSize,
  badgeUrl,
  image,
  name,
  ...props
}) => {
  return <Avatar alignItems="center" background={!!image ? 'transparent' : 'gradient.sunrise'} color="text.primaryWhite" fontWeight="bold" fontFamily="codenext" src={image || undefined} data-testid="user_avatar" name={name} size={avatarSize} borderRadius={'50%'} borderWidth="1px" borderColor="cloud.200" {...props} data-sentry-element="Avatar" data-sentry-component="CbAvatar" data-sentry-source-file="CbAvatar.tsx">
      {badgeUrl && <AvatarBadge boxSize="1em" border="0.1em solid" backgroundColor="white" right={1} bottom="2px" boxShadow="md">
          <Image src={badgeUrl} alt="Badge" fontSize="0" />
        </AvatarBadge>}
      {props.children}
    </Avatar>;
};