import { createIcon } from '@chakra-ui/icon';
const ArcadeToken = createIcon({
  displayName: 'ArcadeToken',
  path: <>
      <path fillRule="evenodd" clipRule="evenodd" d="M11 21.368c5.726 0 10.368-4.642 10.368-10.368S16.726.632 11 .632.632 5.274.632 11 5.274 21.368 11 21.368z" fill="#FFCA13" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.473 19.056l-1.2 2.159c-.65-.11-1.282-.28-1.886-.505l.041-2.473c.928.46 1.957.747 3.045.819zm1.054.001l1.199 2.158c.651-.11 1.282-.28 1.886-.505l-.04-2.464a8.017 8.017 0 01-3.045.811zm3.961-1.341l2.113 1.266a10.42 10.42 0 001.381-1.381l-1.266-2.112a8.11 8.11 0 01-2.228 2.227zm2.758-3.144l2.464.04c.225-.604.395-1.235.504-1.886l-2.157-1.199a8.016 8.016 0 01-.811 3.045zm.81-4.099l2.158-1.199c-.109-.652-.279-1.282-.504-1.887l-2.474.041c.461.928.748 1.957.82 3.045zM17.71 6.521L18.982 4.4a10.423 10.423 0 00-1.381-1.381l-2.127 1.275A8.109 8.109 0 0117.71 6.52zM14.57 3.773l.041-2.483a10.283 10.283 0 00-1.886-.505l-1.209 2.176a8.017 8.017 0 013.054.812zm-4.088-.81L9.273.784c-.65.11-1.282.28-1.886.505l.041 2.492a8.017 8.017 0 013.055-.82zM6.536 4.298L4.399 3.018a10.42 10.42 0 00-1.382 1.381L4.3 6.536A8.108 8.108 0 016.536 4.3zm-2.754 3.13l-2.493-.042a10.284 10.284 0 00-.504 1.887l2.177 1.21a8.016 8.016 0 01.82-3.055zm-.821 4.088L.785 12.726c.11.651.28 1.282.504 1.887l2.484-.041a8.017 8.017 0 01-.812-3.055zm1.332 3.957L3.017 17.6c.417.502.88.965 1.382 1.381l2.122-1.272a8.108 8.108 0 01-2.228-2.236z" fill="#FFE215" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 19.064a8.064 8.064 0 100-16.128 8.064 8.064 0 000 16.128z" fill="#FBE140" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.992 15.667l-4.148-2.334v-4.58l4.148-2.42 4.148 2.42v6.914l-2.072-1.168-2.076 1.168zm-2.063-3.5l2.063 1.166 2.063-1.166V9.898l-2.063-1.145-2.063 1.145v2.269z" fill="#fff" />
    </>
});
export default ArcadeToken;