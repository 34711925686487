export enum Feature {
  AnalyzeDashboard = 'analyze_dashboard',
  GameCenterEnhancements = 'game_center_enhancements',
  GameGuides = 'microlearning_modules',
  IncludeNTIDInReports = 'include_ntid_in_reports',
  PerformanceDashboard = 'performance_dashboard',
  PlayerIdentifiers = 'player_identifiers',
  MetricThresholds = 'metric_thresholds',
  TrackGameSalesBy = 'track_game_sales_by',
}
