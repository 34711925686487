import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import { arcadeApiClient } from '../apiClients';
import { Feature } from '../types';
import { FEATURE_HASH_CHANGED_EVENT_KEY } from '../utils';
interface FeaturesContextProps {
  enabledFeatures: Feature[] | undefined;
}
export const FeaturesContext = createContext({} as FeaturesContextProps);
export const FeaturesProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [enabledFeatures, setEnabledFeatures] = useState<Feature[]>();
  const [featuresLoaded, setFeaturesLoaded] = useState<boolean>(false);
  const loadFeatures = () => arcadeApiClient.get('/multi_platform/features').then(res => setEnabledFeatures(res.data.features)).finally(() => setFeaturesLoaded(true));
  useEffect(() => {
    window.addEventListener(FEATURE_HASH_CHANGED_EVENT_KEY, loadFeatures);
    loadFeatures();
    return () => window.removeEventListener(FEATURE_HASH_CHANGED_EVENT_KEY, loadFeatures);
  }, []);
  return <FeaturesContext.Provider value={{
    enabledFeatures
  }} data-sentry-element="unknown" data-sentry-component="FeaturesProvider" data-sentry-source-file="FeaturesProvider.tsx">
      {featuresLoaded ? children : <Loading />}
    </FeaturesContext.Provider>;
};
function Loading() {
  return <Flex alignItems="center" height="100vh" justifyContent="center" width="100%" data-sentry-element="Flex" data-sentry-component="Loading" data-sentry-source-file="FeaturesProvider.tsx">
      <Spinner color="chonky.500" emptyColor="gray.200" size="lg" speed="0.65s" thickness="4px" data-sentry-element="Spinner" data-sentry-source-file="FeaturesProvider.tsx" />
    </Flex>;
}