import { Icon } from '@chakra-ui/icon';
const FoodCourse = props => <Icon viewBox="0 0 14 13" {...props} data-sentry-element="Icon" data-sentry-component="FoodCourse" data-sentry-source-file="FoodCourse.tsx">
    <g fill="none" data-sentry-element="g" data-sentry-source-file="FoodCourse.tsx">
      <path d="M12.7032 11.3658C12.4724 9.01734 10.6362 7.09584 8.21817 6.53634C8.32921 6.33384 8.39746 6.10784 8.39746 5.86584C8.39746 5.03884 7.66838 4.36584 6.77246 4.36584C5.87654 4.36584 5.14746 5.03884 5.14746 5.86584C5.14746 6.10784 5.21571 6.33384 5.32675 6.53634C2.90875 7.09584 1.0725 9.01734 0.841753 11.3658H0.272461V12.3658H13.182V11.3658H12.7032Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="FoodCourse.tsx" />
      <path d="M4.60579 1.36584H3.52246V3.86584H4.60579V1.36584Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="FoodCourse.tsx" />
      <path d="M10.0225 1.36584H8.93915V3.86584H10.0225V1.36584Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="FoodCourse.tsx" />
      <path d="M7.31411 0.365845H6.23077V2.86584H7.31411V0.365845Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="FoodCourse.tsx" />
    </g>
  </Icon>;
FoodCourse.displayName = 'FoodCourseIcon';
export default FoodCourse;