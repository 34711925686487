import { Icon } from '@chakra-ui/icon';
const Profile = props => <Icon viewBox="0 0 16 16" {...props} data-sentry-element="Icon" data-sentry-component="Profile" data-sentry-source-file="Profile.tsx">
    <g fill="none" data-sentry-element="g" data-sentry-source-file="Profile.tsx">
      <path d="M12.6667 13.6573V13.1607C12.6667 12.4427 12.282 11.78 11.6587 11.424L9.5127 10.196" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" data-sentry-element="path" data-sentry-source-file="Profile.tsx" />
      <path d="M6.48483 10.1947L4.3415 11.424C3.71816 11.78 3.3335 12.4427 3.3335 13.1607V13.6573" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" data-sentry-element="path" data-sentry-source-file="Profile.tsx" />
      <path d="M8.00016 10.6667C6.5275 10.6667 5.3335 9.47267 5.3335 8V6.66667C5.3335 5.194 6.5275 4 8.00016 4C9.47283 4 10.6668 5.194 10.6668 6.66667V8C10.6668 9.47267 9.47283 10.6667 8.00016 10.6667Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="Profile.tsx" />
      <path d="M8.00008 15.3333C12.0502 15.3333 15.3334 12.0501 15.3334 7.99999C15.3334 3.9499 12.0502 0.666656 8.00008 0.666656C3.94999 0.666656 0.666748 3.9499 0.666748 7.99999C0.666748 12.0501 3.94999 15.3333 8.00008 15.3333Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="Profile.tsx" />
    </g>
  </Icon>;
Profile.displayName = 'ProfileIcon';
export default Profile;