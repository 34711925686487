import { Icon } from '@chakra-ui/icon';
const RibbonBlank = props => <Icon viewBox="0 0 40 38" {...props} data-sentry-element="Icon" data-sentry-component="RibbonBlank" data-sentry-source-file="RibbonBlank.tsx">
    <svg width="40" height="38" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="RibbonBlank.tsx">
      <path d="M38.9905 31.1096L34.9905 24.2129C34.9181 24.0886 34.7868 24.0105 34.6431 24.0061C34.4994 24.0016 34.3636 24.0716 34.2838 24.1912C32.25 27.2553 29.2845 29.5839 25.8255 30.8329C25.7082 30.8747 25.6158 30.9668 25.5736 31.0839C25.5313 31.201 25.5436 31.3308 25.6071 31.4379L28.8988 37.0096C29.2284 37.5635 29.8479 37.8777 30.4896 37.8164C31.1313 37.7551 31.68 37.3292 31.8988 36.7229L33.2855 32.9112L37.2721 33.5862C37.9092 33.6935 38.5509 33.423 38.9189 32.8919C39.2868 32.3609 39.3147 31.665 38.9905 31.1062V31.1096Z" fill="url(#paint0_angular_1588_542)" data-sentry-element="path" data-sentry-source-file="RibbonBlank.tsx" />
      <path d="M5.83244 24.1484C5.75306 24.0279 5.61696 23.957 5.4727 23.9611C5.32844 23.9652 5.19656 24.0436 5.1241 24.1684L1.1041 31.1101C0.780543 31.6691 0.809074 32.3647 1.17733 32.8953C1.54558 33.4259 2.18725 33.696 2.8241 33.5884L6.80911 32.9134L8.19744 36.7268C8.41771 37.3323 8.96727 37.7566 9.6088 37.8164C10.2503 37.8763 10.8689 37.561 11.1974 37.0068L14.4974 31.4218C14.5604 31.3148 14.5726 31.1853 14.5308 31.0684C14.4891 30.9519 14.3973 30.8601 14.2808 30.8184C10.8216 29.5598 7.85942 27.2211 5.83244 24.1484Z" fill="url(#paint1_angular_1588_542)" data-sentry-element="path" data-sentry-source-file="RibbonBlank.tsx" />
      <path d="M34.6579 14.7591C34.6579 6.70496 28.1287 0.175781 20.0745 0.175781C12.0204 0.175781 5.49121 6.70496 5.49121 14.7591C5.49121 22.8133 12.0204 29.3424 20.0745 29.3424C28.1249 29.3333 34.6487 22.8095 34.6579 14.7591Z" fill="url(#paint2_angular_1588_542)" data-sentry-element="path" data-sentry-source-file="RibbonBlank.tsx" />
      <defs data-sentry-element="defs" data-sentry-source-file="RibbonBlank.tsx">
        <radialGradient id="paint0_angular_1588_542" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.3822 30.9149) rotate(90) scale(6.90905 6.83337)" data-sentry-element="radialGradient" data-sentry-source-file="RibbonBlank.tsx">
          <stop stopColor="#FFE215" data-sentry-element="stop" data-sentry-source-file="RibbonBlank.tsx" />
          <stop offset="1" stopColor="#F7C11B" data-sentry-element="stop" data-sentry-source-file="RibbonBlank.tsx" />
        </radialGradient>
        <radialGradient id="paint1_angular_1588_542" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.71758 30.8923) rotate(90) scale(6.93137 6.8377)" data-sentry-element="radialGradient" data-sentry-source-file="RibbonBlank.tsx">
          <stop stopColor="#FFE215" data-sentry-element="stop" data-sentry-source-file="RibbonBlank.tsx" />
          <stop offset="1" stopColor="#F7C11B" data-sentry-element="stop" data-sentry-source-file="RibbonBlank.tsx" />
        </radialGradient>
        <radialGradient id="paint2_angular_1588_542" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.0745 14.7591) rotate(90) scale(14.5833)" data-sentry-element="radialGradient" data-sentry-source-file="RibbonBlank.tsx">
          <stop stopColor="#FFE215" data-sentry-element="stop" data-sentry-source-file="RibbonBlank.tsx" />
          <stop offset="1" stopColor="#F7C11B" data-sentry-element="stop" data-sentry-source-file="RibbonBlank.tsx" />
        </radialGradient>
      </defs>
    </svg>
  </Icon>;
RibbonBlank.displayName = 'RibbonBlankIcon';
export default RibbonBlank;