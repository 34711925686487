import { Icon } from '@chakra-ui/icon';
const You = props => <Icon {...props} data-sentry-element="Icon" data-sentry-component="You" data-sentry-source-file="You.tsx">
    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="You.tsx">
      <path d="M8 9C8.9665 9 9.75 8.2165 9.75 7.25C9.75 6.2835 8.9665 5.5 8 5.5C7.0335 5.5 6.25 6.2835 6.25 7.25C6.25 8.2165 7.0335 9 8 9Z" stroke={props?.color || 'white'} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="You.tsx" />
      <path d="M5 12.5C5 11.7044 5.31607 10.9413 5.87868 10.3787C6.44129 9.81607 7.20435 9.5 8 9.5C8.79565 9.5 9.55871 9.81607 10.1213 10.3787C10.6839 10.9413 11 11.7044 11 12.5H5Z" stroke={props?.color || 'white'} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="You.tsx" />
      <path d="M13.5 3.5H2.5C1.94772 3.5 1.5 3.94772 1.5 4.5V11.5C1.5 12.0523 1.94772 12.5 2.5 12.5H13.5C14.0523 12.5 14.5 12.0523 14.5 11.5V4.5C14.5 3.94772 14.0523 3.5 13.5 3.5Z" stroke={props?.color || 'white'} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="You.tsx" />
      <path d="M1.5 0.5H14.5" stroke={props?.color || 'white'} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="You.tsx" />
      <path d="M1.5 15.5H14.5" stroke={props?.color || 'white'} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="You.tsx" />
    </svg>
  </Icon>;
You.displayName = 'You';
export default You;