import { Icon } from '@chakra-ui/icon';
const Transactions = props => <Icon {...props} data-sentry-element="Icon" data-sentry-component="Transactions" data-sentry-source-file="Transactions.tsx">
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF" data-sentry-element="svg" data-sentry-source-file="Transactions.tsx">
      <g data-sentry-element="g" data-sentry-source-file="Transactions.tsx">
        <rect fill="none" height="24" width="24" data-sentry-element="rect" data-sentry-source-file="Transactions.tsx" />
      </g>
      <g data-sentry-element="g" data-sentry-source-file="Transactions.tsx">
        <g data-sentry-element="g" data-sentry-source-file="Transactions.tsx">
          <rect height="7" width="3" x="4" y="10" data-sentry-element="rect" data-sentry-source-file="Transactions.tsx" />
          <rect height="7" width="3" x="10.5" y="10" data-sentry-element="rect" data-sentry-source-file="Transactions.tsx" />
          <rect height="3" width="20" x="2" y="19" data-sentry-element="rect" data-sentry-source-file="Transactions.tsx" />
          <rect height="7" width="3" x="17" y="10" data-sentry-element="rect" data-sentry-source-file="Transactions.tsx" />
          <polygon points="12,1 2,6 2,8 22,8 22,6" data-sentry-element="polygon" data-sentry-source-file="Transactions.tsx" />
        </g>
      </g>
    </svg>
  </Icon>;
Transactions.displayName = 'TransactionsIcon';
export default Transactions;