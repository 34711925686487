import { FC, ReactNode } from 'react';
import { Image, AvatarProps, Box } from '@chakra-ui/react';
export interface CbHexAvatarProps extends AvatarProps {
  avatarSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  badgeUrl?: string;
  image?: string | null;
  name?: string;
  adornment?: ReactNode;
}
export const CbHexAvatar: FC<CbHexAvatarProps> = ({
  avatarSize = 'md',
  badgeUrl,
  image,
  name,
  adornment,
  ...props
}) => {
  const avatarSizeMap = {
    xs: 40,
    sm: 50,
    md: 60,
    lg: 70,
    xl: 80,
    '2xl': 100
  };
  const hexagon = 'polygon(8% 25%, 50% 0, 92% 25%, 92% 75%, 50% 100%, 8% 75%)';
  const size = `${avatarSizeMap[avatarSize]}px`;

  // The badge is 1/3 the size of the avatar
  const badgeSize = `${avatarSizeMap[avatarSize] / 3.33}px`;
  const initials = name?.split(' ').map(n => n[0]).join('').toUpperCase();
  return <Box {...props} position="relative" data-sentry-element="Box" data-sentry-component="CbHexAvatar" data-sentry-source-file="CbHexAvatar.tsx">
      <Box clipPath={hexagon} height={size} width={size} maxWidth={size} padding="1.55px" backgroundColor="white" display="flex" justifyContent="center" data-testid="user_hex_avatar" data-sentry-element="Box" data-sentry-source-file="CbHexAvatar.tsx">
        {!!image ? <Image src={image} clipPath={hexagon} /> : <Box width={size} background="gradient.sunrise" display="flex" justifyContent="center" alignItems="center" color="white" fontWeight="bold" fontFamily="codenext"
      // This is a bit of a magic number, after some tweaking this is what looks best for the font size within the component if no image is set
      fontSize={avatarSizeMap[avatarSize] / 3} clipPath={hexagon}>
            {initials}
          </Box>}
      </Box>
      {badgeUrl && <Image position="absolute" bottom="0" right=".5rem" src={badgeUrl} alt="Badge" maxWidth={badgeSize} border="1px solid white" borderRadius="50%" />}
      {adornment}
    </Box>;
};