import { createContext, FC, PropsWithChildren, useState } from 'react';
import { AnalyzeType } from 'components/AnalyzeTypeHeader';
import { PeriodType } from 'components/AnalyzePeriodHeader';
export interface SelectionOption {
  label: string;
  value: string | number;
}
export interface FilterOptions {
  audience: SelectionOption | null;
  game: SelectionOption | null;
  graphType: SelectionOption | null;
  metric: SelectionOption | null;
  periodCount: SelectionOption | null;
  periodType: SelectionOption | null;
  metricReportingRange: Date[] | null;
  customComparisonPeriods: {
    [key: number]: Date[];
  };
}
const defaultFilterOptions = {
  audience: null,
  game: null,
  metric: null,
  graphType: null,
  periodCount: {
    value: 3,
    label: '3'
  },
  periodType: {
    value: PeriodType.Sequential,
    label: 'Sequential'
  },
  metricReportingRange: [],
  customComparisonPeriods: {}
};
export const AnalyzeDashboardContext = createContext({
  clearGraphFilterOptions: () => {},
  graphFilterOptions: defaultFilterOptions as FilterOptions,
  setGraphFilterOptions: options => {},
  analyzeType: AnalyzeType.Metric,
  handleAnalyzeTypeChange: type => {}
});
export const AnalyzeDashboardProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [graphFilterOptions, setGraphFilterOptions] = useState<FilterOptions>(defaultFilterOptions);
  const [analyzeType, setAnalyzeType] = useState<AnalyzeType>(AnalyzeType.Metric);
  const clearGraphFilterOptions = () => {
    setGraphFilterOptions(defaultFilterOptions);
  };
  const handleAnalyzeTypeChange = (type: AnalyzeType) => {
    setAnalyzeType(type);
    clearGraphFilterOptions();
  };
  const value = {
    clearGraphFilterOptions,
    graphFilterOptions,
    setGraphFilterOptions,
    analyzeType,
    handleAnalyzeTypeChange
  };
  return <AnalyzeDashboardContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="AnalyzeDashboardProvider" data-sentry-source-file="AnalyzeDashboardProvider.tsx">
      {children}
    </AnalyzeDashboardContext.Provider>;
};