import { IconProps } from '@chakra-ui/icon';
import { ComponentWithAs } from '@chakra-ui/react';
import Account from './Account';
import Airplane from './Airplane';
import AlarmBell from './AlarmBell';
import AlarmBellOff from './AlarmBellOff';
import AlarmBellSnooze from './AlarmBellSnooze';
import ArcadeChest from './ArcadeChest';
import ArcadeChestSolidColor from './ArcadeChestSolid';
import ArcadeToken from './ArcadeToken';
import Archive from './Archive';
import ArrowDown from './ArrowDown';
import ArrowDownLeft from './ArrowDownLeft';
import ArrowDownRight from './ArrowDownRight';
import ArrowFatUp from './ArrowFatUp';
import ArrowLeft from './ArrowLeft';
import ArrowLongLeft from './ArrowLongLeft';
import ArrowLongRight from './ArrowLongRight';
import ArrowRight from './ArrowRight';
import ArrowUp from './ArrowUp';
import AtSign from './AtSign';
import Attachment from './Attachment';
import AttachmentAlternate from './AttachmentAlternate';
import Award from './Award';
import BadgeStar from './BadgeStar';
import Balloon from './Balloon';
import BatteryAhFuck from './BatteryAhFuck';
import BatteryFull from './BatteryFull';
import BatteryLow from './BatteryLow';
import BatteryMedium from './BatteryMedium';
import Beaker from './Beaker';
import Bolt from './Bolt';
import BoltAlternate from './BoltAlternate';
import BoltRadiate from './BoltRadiate';
import Bookmark from './Bookmark';
import BookmarkAlternate from './BookmarkAlternate';
import BriefCase from './BriefCase/BriefCase';
import CSV from './CSV';
import Calendar from './Calendar';
import CalendarAlternative from './CalendarAlternative';
import CautionCircle from './CautionCircle';
import CautionDiamond from './CautionDiamond';
import CautionTriangle from './CautionTriangle';
import ChatBubbleCautionOutline from './ChatBubbleCautionOutline';
import ChatBubbleDots from './ChatBubbleDots';
import ChatBubbleDotsOutline from './ChatBubbleDotsOutline';
import ChatBubbleLinesOutline from './ChatBubbleLinesOutline';
import ChatIcon from './ChatIcon';
import Check from './Check';
import CheckAll from './CheckAll';
import CheckCircle from './CheckCircle';
import CheckCircleOutline from './CheckCircleOutline';
import Checkbox from './Checkbox';
import CheckboxOutline from './CheckboxOutline';
import CheckeredFlag from './CheckeredFlag';
import ChessKnight from './ChessKnight';
import ChessPawn from './ChessPawn';
import ChessPieces from './ChessPieces';
import ChessRook from './ChessRook';
import ChevronDown from './ChevronDown';
import ChevronDownWide from './ChevronDownWide';
import ChevronLeft from './ChevronLeft';
import ChevronLeftThin from './ChevronLeftThin';
import ChevronRight from './ChevronRight';
import ChevronUp from './ChevronUp';
import Circle from './Circle';
import CircleOutline from './CircleOutline';
import CircleWithHole from './CircleWithHole';
import Clipboard from './Clipboard';
import Close from './Close';
import CloseCircle from './CloseCircle';
import CloseCircleOutline from './CloseCircleOutline';
import Clover from './Clover';
import CloverFour from './CloverFour';
import Coins from './Coins/Coins';
import Columns from './Columns';
import Comment from './Comment';
import Compass from './Compass';
import CompassOutline from './CompassOutline';
import Computer from './Computer';
import Confetti from './Confetti';
import Crown from './Crown';
import CrownCircle from './CrownCircle';
import CrownDiamond from './CrownDiamond';
import Cursor from './Cursor';
import CursorAlternateOutline from './CursorAlternateOutline';
import CursorHand from './CursorHand';
import CursorOutline from './CursorOutline';
import Dashboard from './Dashboard';
import DirectionPad from './DirectionPad';
import DirectionPadAlternate from './DirectionPadAlternate';
import Directory from './Directory';
import DotsHorizontal from './DotsHorizontal';
import DotsVertical from './DotsVertical';
import Edit from './Edit';
import EditAlternative from './EditAlternative';
import Email from './Email';
import EmailOutline from './EmailOutline';
import Eye from './Eye';
import EyeCrossed from './EyeCrossed';
import File from './File';
import FileFolder from './FileFolder';
import FileFolderOutline from './FileFolderOutline';
import FileOutline from './FileOutline';
import Files from './Files';
import FilesAlternate from './FilesAlternate';
import FilterOutline from './FilterOutline';
import Flag from './Flag';
import FlagAnother from './FlagAnother';
import FlagCheck from './FlagCheck';
import FlagGo from './FlagGo';
import FlagGolf from './FlagGolf';
import FlagTwo from './FlagTwo';
import FlagWind from './FlagWind';
import FloppyDisk from './FloppyDisk';
import FoodCourse from './FoodCourse';
import GameCards from './GameCards';
import GameController from './GameController';
import GameControllerOne from './GameControllerOne';
import GameControllerPeople from './GameControllerPeople';
import GameControllerTwo from './GameControllerTwo';
import GameDice from './GameDice';
import GameHorseshoe from './GameHorseshoe';
import GameJoystick from './GameJoystick';
import GameMushroom from './GameMushroom';
import GamePacman from './GamePacman';
import GamePickaxe from './GamePickaxe';
import GameSpaceInvader from './GameSpaceInvader';
import GameSword from './GameSword';
import GameWand from './GameWand';
import GameWandStar from './GameWandStar';
import Gauge from './Gauge';
import GeoPin from './GeoPin';
import GiftOne from './GiftOne';
import GiftThree from './GiftThree';
import GiftTwo from './GiftTwo';
import GlobalFeed from './GlobalFeed';
import Group from './Group';
import GuageOutline from './GuageOutline';
import HTML from './HTML';
import Heart from './Heart';
import HeartCalendar from './HeartCalendar';
import HeartOutline from './HeartOutline';
import HeartOutlineShadow from './HeartOutlineShadow';
import HeartPixel from './HeartPixel';
import HelpCircle from './HelpCircle';
import History from './History';
import Home from './Home';
import HomeOutline from './HomeOutline';
import HomeOutlineTwo from './HomeOutlineTwo';
import Hot from './Hot';
import Inbox from './Inbox';
import InboxIncoming from './InboxIncoming';
import InboxOutgoing from './InboxOutgoing';
import InfoCircle from './InfoCircle';
import InformationCircle from './InformationCircle';
import Launch from './Launch';
import Layers from './Layers';
import LayersBack from './LayersBack';
import LayersTogether from './LayersTogether';
import LeftPagination from './LeftPagination';
import Lemon from './Lemon';
import Link from './Link';
import ListBullets from './ListBullets';
import ListNumbers from './ListNumbers';
import Loading from './Loading';
import Location from './Location';
import LocationCrossed from './LocationCrossed';
import LocationCursor from './LocationCursor';
import LocationFound from './LocationFound';
import Lock from './Lock';
import Login from './Login';
import LoginCircle from './LoginCircle';
import Logout from './Logout';
import LogoutCircle from './LogoutCircle';
import LogoutWide from './LogoutWide';
import Magnet from './Magnet';
import Map from './Map';
import MapSearch from './MapSearch';
import Measure from './Measure';
import Medal from './Medal';
import MedalFirst from './MedalFirst';
import MedalShiny from './MedalShiny';
import Menu from './Menu';
import MenuSlim from './MenuSlim';
import MenuWide from './MenuWide';
import MessageBubble from './MessageBubble';
import MessageBubbleCenter from './MessageBubbleCenter';
import MessageBubbleCenterOutline from './MessageBubbleCenterOutline';
import MessageBubbleOutline from './MessageBubbleOutline';
import MessageBubbles from './MessageBubbles';
import MessageBubblesOutline from './MessageBubblesOutline';
import MilitaryRankOne from './MilitaryRankOne';
import MilitaryRankStar from './MilitaryRankStar';
import MilitaryRankThree from './MilitaryRankThree';
import MilitaryRankTwo from './MilitaryRankTwo';
import Minus from './Minus';
import Molecule from './Molecule';
import MoneyBagDiamond from './MoneyBagDiamond';
import MoreCircle from './MoreCircle';
import MoreCircleOutline from './MoreCircleOutline';
import Mountain from './Mountain';
import NoTokens from './NoTokens';
import Notes from './Notes';
import Notifications from './Notifications';
import OpenArcadeChest from './OpenArcadeChest';
import PDF from './PDF';
import Party from './Party/Party';
import PartyDecorations from './PartyDecorations';
import Peace from './Peace';
import Pencil from './Pencil';
import People from './People';
import Person from './Person';
import Phone from './Phone';
import PhoneTwo from './PhoneTwo';
import Pin from './Pin';
import PinAlternate from './PinAlternate';
import Play from './Play';
import Plus from './Plus';
import PlusCircle from './PlusCircle';
import Podium from './Podium';
import PodiumAlternate from './PodiumAlternate';
import Posts from './Posts';
import Presentation from './Presentation';
import Printer from './Printer';
import Profile from './Profile';
import Pulse from './Pulse';
import QuestionCircle from './QuestionCircle';
import QuestionCircleHollow from './QuestionCircleHollow';
import Quill from './Quill';
import QuillAlternate from './QuillAlternate';
import QuillThree from './QuillThree';
import Rectangle from './Rectangle';
import RectangleStar from './RectangleStar';
import Redo from './Redo';
import Restart from './Restart';
import RibbonBlank from './RibbonBlank';
import RibbonCheckOne from './RibbonCheckOne';
import RibbonCheckTwo from './RibbonCheckTwo';
import RibbonFirst from './RibbonFirst';
import RibbonFirstThree from './RibbonFirstThree';
import RibbonFirstTwo from './RibbonFirstTwo';
import RibbonOne from './RibbonOne';
import RibbonStarOne from './RibbonStarOne';
import RibbonStarTwo from './RibbonStarTwo';
import RightPagination from './RightPagination';
import RockOn from './RockOn';
import Rocket from './Rocket';
import RoundedRectangle from './RoundedRectangle';
import RoundedRectangleEmpty from './RoundedRectangleEmpty';
import RoundedRectangleOutline from './RoundedRectangleOutline';
import Search from './Search';
import SearchCircle from './SearchCircle';
import SearchPerson from './SearchPerson';
import Send from './Send';
import SendAlternate from './SendAlternate';
import SettingsCog from './SettingsCog';
import SettingsCogOutline from './SettingsCogOutline';
import SettingsCogs from './SettingsCogs';
import Share from './Share';
import Shield from './Shield';
import ShieldAlternateOutline from './ShieldAlternateOutline';
import ShieldOutline from './ShieldOutline';
import SizeExpand from './SizeExpand';
import SizeShrink from './SizeShrink';
import Speedometer from './Speedometer';
import SpeedometerOutline from './SpeedometerOutline';
import Star from './Star';
import StarBadge from './StarBadge';
import StarCircle from './StarCircle';
import StarExperience from './StarExperience';
import StarGroup from './StarGroup';
import StarHalf from './StarHalf';
import StarHalfOutline from './StarHalfOutline';
import StarOutline from './StarOutline';
import StarSmall from './StarSmall';
import StatSearch from './StatSearch';
import StatsAscend from './StatsAscend';
import StatsDescend from './StatsDescend';
import StatsFive from './StatsFive';
import StatsGraph from './StatsGraph';
import StatsGraphAlternate from './StatsGraphAlternate';
import StatsGraphBackground from './StatsGraphBackground';
import StatsPieChart from './StatsPieChart';
import StatsPieChartTwo from './StatsPieChartTwo';
import Store from './Store';
import Sync from './Sync';
import SyncAlternate from './SyncAlternate';
import SyncFour from './SyncFour';
import SyncThree from './SyncThree';
import TV from './TV';
import TXT from './TXT';
import Tag from './Tag';
import TagsStack from './TagsStack';
import Target from './Target';
import TargetAlternate from './TargetAlternate';
import Team from './Team';
import ThumbsDown from './ThumbsDown';
import ThumbsUp from './ThumbsUp';
import ThumbsUpFill from './ThumbsUpFill';
import Ticket from './Ticket';
import Timer from './Timer';
import Tinder from './Tinder';
import TokenHistory from './TokenHistory';
import TokensLarge from './TokensLarge';
import TokensMedium from './TokensMedium';
import TokensSmall from './TokensSmall';
import ToolBolt from './ToolBolt';
import ToolWrench from './ToolWrench';
import Tools from './Tools';
import TorchOne from './TorchOne';
import TourchThree from './TourchThree';
import TourchTwo from './TourchTwo';
import Transactions from './Transactions/Transactions';
import Transfer from './Transfer';
import Trash from './Trash';
import TrophyCheck from './TrophyCheck';
import TrophyStar from './TrophyStar';
import TrophyStarAlternate from './TrophyStarAlternate';
import TrophyThree from './TrophyThree';
import Undo from './Undo';
import Unlock from './Unlock';
import User from './User';
import UserFull from './UserFull';
import UserFullGroup from './UserFullGroup';
import Users from './Users';
import VIPCircle from './VIPCircle';
import VideoGameMushroom from './VideoGameMushroom';
import VideoPlay from './VideoPlay';
import VideoPlayOutline from './VideoPlayOutline';
import Wrench from './Wrench';
import You from './You';
import ZIP from './ZIP';
export const ICON_BY_NAME = {
  account: Account,
  airplane: Airplane,
  alarmbell: AlarmBell,
  alarmbelloff: AlarmBellOff,
  alarmbellsnooze: AlarmBellSnooze,
  arcadechest: ArcadeChest,
  arcadechestsolid: ArcadeChestSolidColor,
  arcadetoken: ArcadeToken,
  archive: Archive,
  arrowdown: ArrowDown,
  arrowdownleft: ArrowDownLeft,
  arrowdownright: ArrowDownRight,
  arrowfatup: ArrowFatUp,
  arrowleft: ArrowLeft,
  arrowlongleft: ArrowLongLeft,
  arrowlongright: ArrowLongRight,
  arrowright: ArrowRight,
  arrowup: ArrowUp,
  atsign: AtSign,
  attachment: Attachment,
  attachmentalternate: AttachmentAlternate,
  award: Award,
  badgestar: BadgeStar,
  balloon: Balloon,
  batteryahfuck: BatteryAhFuck,
  batteryfull: BatteryFull,
  batterylow: BatteryLow,
  batterymedium: BatteryMedium,
  beaker: Beaker,
  bolt: Bolt,
  boltalternate: BoltAlternate,
  boltradiate: BoltRadiate,
  bookmark: Bookmark,
  bookmarkalternate: BookmarkAlternate,
  briefcase: BriefCase,
  calendar: Calendar,
  calendaralternative: CalendarAlternative,
  cautioncircle: CautionCircle,
  cautiondiamond: CautionDiamond,
  cautiontriangle: CautionTriangle,
  chatbubblecautionoutline: ChatBubbleCautionOutline,
  chatbubbledots: ChatBubbleDots,
  chatbubbledotsoutline: ChatBubbleDotsOutline,
  chatbubblelinesoutline: ChatBubbleLinesOutline,
  chaticon: ChatIcon,
  check: Check,
  checkall: CheckAll,
  checkbox: Checkbox,
  checkboxoutline: CheckboxOutline,
  checkcircle: CheckCircle,
  checkcircleoutline: CheckCircleOutline,
  checkeredflag: CheckeredFlag,
  chessknight: ChessKnight,
  chesspawn: ChessPawn,
  chesspieces: ChessPieces,
  chessrook: ChessRook,
  chevrondown: ChevronDown,
  chevrondownwide: ChevronDownWide,
  chevronleft: ChevronLeft,
  chevronleftthin: ChevronLeftThin,
  chevronright: ChevronRight,
  chevronup: ChevronUp,
  circle: Circle,
  circleoutline: CircleOutline,
  circlewithhole: CircleWithHole,
  clipboard: Clipboard,
  close: Close,
  closecircle: CloseCircle,
  closecircleoutline: CloseCircleOutline,
  clover: Clover,
  cloverfour: CloverFour,
  coins: Coins,
  columns: Columns,
  comment: Comment,
  compass: Compass,
  compassoutline: CompassOutline,
  computer: Computer,
  confetti: Confetti,
  crown: Crown,
  crowncircle: CrownCircle,
  crowndiamond: CrownDiamond,
  csv: CSV,
  cursor: Cursor,
  cursoralternateoutline: CursorAlternateOutline,
  cursorhand: CursorHand,
  cursoroutline: CursorOutline,
  dashboard: Dashboard,
  directionpad: DirectionPad,
  directionpadalternate: DirectionPadAlternate,
  directory: Directory,
  dotshorizontal: DotsHorizontal,
  dotsvertical: DotsVertical,
  edit: Edit,
  editalternative: EditAlternative,
  email: Email,
  emailoutline: EmailOutline,
  eye: Eye,
  eyecrossed: EyeCrossed,
  file: File,
  filefolder: FileFolder,
  filefolderoutline: FileFolderOutline,
  fileoutline: FileOutline,
  files: Files,
  filesalternate: FilesAlternate,
  filteroutline: FilterOutline,
  flag: Flag,
  flaganother: FlagAnother,
  flagcheck: FlagCheck,
  flaggo: FlagGo,
  flaggolf: FlagGolf,
  flagtwo: FlagTwo,
  flagwind: FlagWind,
  floppydisk: FloppyDisk,
  foodcourse: FoodCourse,
  gamecards: GameCards,
  gamecontroller: GameController,
  gamecontrollerone: GameControllerOne,
  gamecontrollerpeople: GameControllerPeople,
  gamecontrollertwo: GameControllerTwo,
  gamedice: GameDice,
  gamehorseshoe: GameHorseshoe,
  gamejoystick: GameJoystick,
  gamemushroom: GameMushroom,
  gamepacman: GamePacman,
  gamepickaxe: GamePickaxe,
  gamespaceinvader: GameSpaceInvader,
  gamesword: GameSword,
  gamewand: GameWand,
  gamewandstar: GameWandStar,
  gauge: Gauge,
  geopin: GeoPin,
  giftone: GiftOne,
  giftthree: GiftThree,
  gifttwo: GiftTwo,
  globalfeed: GlobalFeed,
  group: Group,
  guageoutline: GuageOutline,
  heart: Heart,
  heartcalendar: HeartCalendar,
  heartoutline: HeartOutline,
  heartoutlineshadow: HeartOutlineShadow,
  heartpixel: HeartPixel,
  helpcircle: HelpCircle,
  history: History,
  home: Home,
  homeoutline: HomeOutline,
  homeoutlinetwo: HomeOutlineTwo,
  hot: Hot,
  html: HTML,
  inbox: Inbox,
  inboxincoming: InboxIncoming,
  inboxoutgoing: InboxOutgoing,
  infocircle: InfoCircle,
  informationcircle: InformationCircle,
  launch: Launch,
  layers: Layers,
  layersback: LayersBack,
  layerstogether: LayersTogether,
  leftpagination: LeftPagination,
  lemon: Lemon,
  link: Link,
  listbullets: ListBullets,
  listnumbers: ListNumbers,
  loading: Loading,
  location: Location,
  locationcrossed: LocationCrossed,
  locationcursor: LocationCursor,
  locationfound: LocationFound,
  lock: Lock,
  login: Login,
  logincircle: LoginCircle,
  logout: Logout,
  logoutcircle: LogoutCircle,
  logoutwide: LogoutWide,
  magnet: Magnet,
  map: Map,
  mapsearch: MapSearch,
  measure: Measure,
  medal: Medal,
  medalfirst: MedalFirst,
  medalshiny: MedalShiny,
  menu: Menu,
  menuslim: MenuSlim,
  menuwide: MenuWide,
  messagebubble: MessageBubble,
  messagebubblecenter: MessageBubbleCenter,
  messagebubblecenteroutline: MessageBubbleCenterOutline,
  messagebubbleoutline: MessageBubbleOutline,
  messagebubbles: MessageBubbles,
  messagebubblesoutline: MessageBubblesOutline,
  militaryrankone: MilitaryRankOne,
  militaryrankstar: MilitaryRankStar,
  militaryrankthree: MilitaryRankThree,
  militaryranktwo: MilitaryRankTwo,
  minus: Minus,
  molecule: Molecule,
  moneybagdiamond: MoneyBagDiamond,
  morecircle: MoreCircle,
  morecircleoutline: MoreCircleOutline,
  mountain: Mountain,
  notes: Notes,
  notifications: Notifications,
  notokens: NoTokens,
  openarcadechest: OpenArcadeChest,
  party: Party,
  partydecorations: PartyDecorations,
  pdf: PDF,
  peace: Peace,
  pencil: Pencil,
  people: People,
  person: Person,
  phone: Phone,
  phonetwo: PhoneTwo,
  pin: Pin,
  pinalternate: PinAlternate,
  play: Play,
  plus: Plus,
  pluscircle: PlusCircle,
  podium: Podium,
  podiumalternate: PodiumAlternate,
  posts: Posts,
  presentation: Presentation,
  printer: Printer,
  profile: Profile,
  pulse: Pulse,
  questioncircle: QuestionCircle,
  questioncirclehollow: QuestionCircleHollow,
  quill: Quill,
  quillalternate: QuillAlternate,
  quillthree: QuillThree,
  rectangle: Rectangle,
  rectanglestar: RectangleStar,
  redo: Redo,
  restart: Restart,
  ribbonblank: RibbonBlank,
  ribboncheckone: RibbonCheckOne,
  ribbonchecktwo: RibbonCheckTwo,
  ribbonfirst: RibbonFirst,
  ribbonfirstthree: RibbonFirstThree,
  ribbonfirsttwo: RibbonFirstTwo,
  ribbonone: RibbonOne,
  ribbonstarone: RibbonStarOne,
  ribbonstartwo: RibbonStarTwo,
  rightpagination: RightPagination,
  rocket: Rocket,
  rockon: RockOn,
  roundedrectangle: RoundedRectangle,
  roundedrectangleempty: RoundedRectangleEmpty,
  roundedrectangleoutline: RoundedRectangleOutline,
  search: Search,
  searchcircle: SearchCircle,
  searchperson: SearchPerson,
  send: Send,
  sendalternate: SendAlternate,
  settingscog: SettingsCog,
  settingscogoutline: SettingsCogOutline,
  settingscogs: SettingsCogs,
  share: Share,
  shield: Shield,
  shieldalternateoutline: ShieldAlternateOutline,
  shieldoutline: ShieldOutline,
  sizeexpand: SizeExpand,
  sizeshrink: SizeShrink,
  speedometer: Speedometer,
  speedometeroutline: SpeedometerOutline,
  star: Star,
  starbadge: StarBadge,
  starcircle: StarCircle,
  starexperience: StarExperience,
  stargroup: StarGroup,
  starhalf: StarHalf,
  starhalfoutline: StarHalfOutline,
  staroutline: StarOutline,
  starsmall: StarSmall,
  statsascend: StatsAscend,
  statsdescend: StatsDescend,
  statsearch: StatSearch,
  statsfive: StatsFive,
  statsgraph: StatsGraph,
  statsgraphalternate: StatsGraphAlternate,
  statsgraphbackground: StatsGraphBackground,
  statspiechart: StatsPieChart,
  statspiecharttwo: StatsPieChartTwo,
  store: Store,
  sync: Sync,
  syncalternate: SyncAlternate,
  syncfour: SyncFour,
  syncthree: SyncThree,
  tag: Tag,
  tagsstack: TagsStack,
  target: Target,
  targetalternate: TargetAlternate,
  team: Team,
  thumbsdown: ThumbsDown,
  thumbsup: ThumbsUp,
  thumbsupfill: ThumbsUpFill,
  ticket: Ticket,
  timer: Timer,
  tinder: Tinder,
  tokenhistory: TokenHistory,
  tokenslarge: TokensLarge,
  tokensmedium: TokensMedium,
  tokenssmall: TokensSmall,
  toolbolt: ToolBolt,
  tools: Tools,
  toolwrench: ToolWrench,
  torchone: TorchOne,
  tourchthree: TourchThree,
  tourchtwo: TourchTwo,
  transactions: Transactions,
  transfer: Transfer,
  trash: Trash,
  trophycheck: TrophyCheck,
  trophystar: TrophyStar,
  trophystaralternate: TrophyStarAlternate,
  trophythree: TrophyThree,
  tv: TV,
  txt: TXT,
  undo: Undo,
  unlock: Unlock,
  user: User,
  userfull: UserFull,
  userfullgroup: UserFullGroup,
  users: Users,
  videogamemushroom: VideoGameMushroom,
  videoplay: VideoPlay,
  videoplayoutline: VideoPlayOutline,
  vipcircle: VIPCircle,
  wrench: Wrench,
  you: You,
  zip: ZIP
};
export interface Props extends IconProps {
  name?: string;
}
const Icon: ComponentWithAs<'svg', Props> = ({
  name = '',
  ...rest
}) => {
  const IconComponent = ICON_BY_NAME[name.toLowerCase()];
  return <IconComponent {...rest} data-sentry-element="IconComponent" data-sentry-component="Icon" data-sentry-source-file="Icon.tsx" />;
};
Icon.displayName = 'ArcadeIcon';
export default Icon;