import { Icon } from '@chakra-ui/icon';
const GameController = props => <Icon {...props} data-sentry-element="Icon" data-sentry-component="GameController" data-sentry-source-file="GameController.tsx">
    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="GameController.tsx">
      <path d="M6.66675 4.14731V9.99997H9.33341V4.14731" stroke="#ffffff" strokeMiterlimit="10" data-sentry-element="path" data-sentry-source-file="GameController.tsx" />
      <path d="M8 4.66666C9.10457 4.66666 10 3.77123 10 2.66666C10 1.56209 9.10457 0.666656 8 0.666656C6.89543 0.666656 6 1.56209 6 2.66666C6 3.77123 6.89543 4.66666 8 4.66666Z" stroke="#ffffff" strokeMiterlimit="10" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="GameController.tsx" />
      <path d="M14.6667 12.6667H1.33337" stroke="#ffffff" strokeMiterlimit="10" data-sentry-element="path" data-sentry-source-file="GameController.tsx" />
      <path d="M9.33337 7.33333H12L14.6667 12.6667V15.3333H1.33337V12.6667L4.00004 7.33333H6.66671" stroke="#ffffff" strokeMiterlimit="10" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="GameController.tsx" />
    </svg>
  </Icon>;
GameController.displayName = 'GameControllerIcon';
export default GameController;