import { FC, useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalCloseButton, Text } from '@chakra-ui/react';
import * as Sentry from '@sentry/browser';
import { CbButton } from 'ui';
type FallbackUIProps = {
  error: any;
};
export const CbFallbackUI: FC<FallbackUIProps> = ({
  error
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(true);
  useEffect(() => {
    Sentry.captureMessage('Error Boundary Visible', {
      extra: {
        message: error.message,
        name: error.name
      }
    });
  }, []);
  const closeDialog = () => setDialogOpen(false);
  const handleClose = () => closeDialog();
  const handleReload = () => {
    if (window) {
      setTimeout(() => {
        // wait for analytics event to submit before reloading
        window.location.reload();
      }, 400);
    }
  };
  return <Modal isOpen={dialogOpen} onClose={handleClose} aria-labelledby="error-dialog-title" aria-describedby="error-dialog-description" data-sentry-element="Modal" data-sentry-component="CbFallbackUI" data-sentry-source-file="CbFallbackUI.tsx">
      <ModalOverlay data-sentry-element="ModalOverlay" data-sentry-source-file="CbFallbackUI.tsx" />
      <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="CbFallbackUI.tsx">
        <ModalHeader id="error-dialog-title" data-sentry-element="ModalHeader" data-sentry-source-file="CbFallbackUI.tsx">
          <Text data-sentry-element="Text" data-sentry-source-file="CbFallbackUI.tsx">{'Application Error'}</Text>
          <ModalCloseButton data-sentry-element="ModalCloseButton" data-sentry-source-file="CbFallbackUI.tsx" />
        </ModalHeader>
        <Text paddingLeft={'2.5rem'} paddingRight={'2.5rem'} data-sentry-element="Text" data-sentry-source-file="CbFallbackUI.tsx">
          Arcade has detected an error, and needs to reload the page to recover.
          Our team has been notified and are working hard to prevent this from
          happening again.
        </Text>

        <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="CbFallbackUI.tsx">
          <CbButton onClick={handleReload} data-sentry-element="CbButton" data-sentry-source-file="CbFallbackUI.tsx">{'Reload Page'}</CbButton>
        </ModalFooter>
      </ModalContent>
    </Modal>;
};