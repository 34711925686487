import { Icon } from '@chakra-ui/icon';
const Notifications = props => <Icon {...props} data-sentry-element="Icon" data-sentry-component="Notifications" data-sentry-source-file="Notifications.tsx">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Notifications.tsx">
      <g clipPath="url(#clip0)" data-sentry-element="g" data-sentry-source-file="Notifications.tsx">
        <path d="M14.45 21.5C14.2134 22.6652 13.1889 23.5025 12 23.5025C10.811 23.5025 9.78658 22.6652 9.54999 21.5" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Notifications.tsx" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4 11.5C4 7.08172 7.58172 3.5 12 3.5C16.4183 3.5 20 7.08172 20 11.5V18C20 18.8284 20.6716 19.5 21.5 19.5H2.5C3.32843 19.5 4 18.8284 4 18V11.5Z" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Notifications.tsx" />
        <path d="M14.5 6.96899C16.0313 7.72875 17 9.29055 17 11" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Notifications.tsx" />
        <path d="M12 0.501007V3.50101" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Notifications.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="Notifications.tsx">
        <clipPath id="clip0" data-sentry-element="clipPath" data-sentry-source-file="Notifications.tsx">
          <rect width="24" height="24" fill="white" data-sentry-element="rect" data-sentry-source-file="Notifications.tsx" />
        </clipPath>
      </defs>
    </svg>
  </Icon>;
Notifications.displayName = 'NotificationsIcon';
export default Notifications;