import { Icon } from '@chakra-ui/icon';
const Team = props => <Icon {...props} data-sentry-element="Icon" data-sentry-component="Team" data-sentry-source-file="Team.tsx">
    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Team.tsx">
      <path d="M2 3.5C2 3.69698 2.0388 3.89204 2.11418 4.07403C2.18956 4.25601 2.30005 4.42137 2.43934 4.56066C2.57863 4.69995 2.74399 4.81044 2.92597 4.88582C3.10796 4.9612 3.30302 5 3.5 5C3.69698 5 3.89204 4.9612 4.07403 4.88582C4.25601 4.81044 4.42137 4.69995 4.56066 4.56066C4.69995 4.42137 4.81044 4.25601 4.88582 4.07403C4.9612 3.89204 5 3.69698 5 3.5C5 3.30302 4.9612 3.10796 4.88582 2.92597C4.81044 2.74399 4.69995 2.57863 4.56066 2.43934C4.42137 2.30005 4.25601 2.18956 4.07403 2.11418C3.89204 2.0388 3.69698 2 3.5 2C3.30302 2 3.10796 2.0388 2.92597 2.11418C2.74399 2.18956 2.57863 2.30005 2.43934 2.43934C2.30005 2.57863 2.18956 2.74399 2.11418 2.92597C2.0388 3.10796 2 3.30302 2 3.5V3.5Z" stroke={props?.color} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Team.tsx" />
      <path d="M3.70684 7.14054C3.40411 7.0158 3.07826 6.97318 2.75718 7.01635C2.43611 7.05952 2.12938 7.18719 1.86328 7.38841C1.59719 7.58964 1.37964 7.85843 1.22929 8.17175C1.07894 8.48508 1.00027 8.8336 1 9.18747V10.5H1.79989L2.19984 14H3.80016L4.20011 10.5H5" stroke={props?.color} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Team.tsx" />
      <path d="M11 3.5C11 3.89782 11.158 4.27936 11.4393 4.56066C11.7206 4.84196 12.1022 5 12.5 5C12.8978 5 13.2794 4.84196 13.5607 4.56066C13.842 4.27936 14 3.89782 14 3.5C14 3.10218 13.842 2.72064 13.5607 2.43934C13.2794 2.15804 12.8978 2 12.5 2C12.1022 2 11.7206 2.15804 11.4393 2.43934C11.158 2.72064 11 3.10218 11 3.5V3.5Z" stroke={props?.color} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Team.tsx" />
      <path d="M12.7437 7.0175C12.8286 7.00576 12.9141 6.99992 12.9997 7C13.5302 7 14.039 7.23047 14.4141 7.6407C14.7893 8.05094 15 8.60734 15 9.1875V10.5H14.1999L13.7998 14H12.2002L11.8001 10.5H11" stroke={props?.color} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Team.tsx" />
      <path d="M9.3525 4L5.77917 7.57333C5.74421 7.60834 5.72042 7.65292 5.71081 7.70145C5.70121 7.74998 5.70621 7.80027 5.7252 7.84595C5.74419 7.89163 5.7763 7.93065 5.81747 7.95808C5.85865 7.9855 5.90703 8.00009 5.9565 8H7.3525V11L10.9258 7.42667C10.9608 7.3917 10.9845 7.34718 10.9942 7.29871C11.0038 7.25025 10.9988 7.20001 10.9799 7.15436C10.961 7.1087 10.929 7.06968 10.8879 7.0422C10.8469 7.01473 10.7986 7.00004 10.7492 7H9.3525V4Z" stroke={props?.color} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Team.tsx" />
    </svg>
  </Icon>;
Team.displayName = 'Team';
export default Team;