import { Icon } from '@chakra-ui/icon';
const ArcadeChestSolidColor = props => <Icon viewBox="0 0 256 224" {...props} data-sentry-element="Icon" data-sentry-component="ArcadeChestSolidColor" data-sentry-source-file="ArcadeChestSolidColor.tsx">
    <path d="M4.43091 96.4102H250.831L217.108 154.01H38.8924L4.43091 96.4102Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M1.96936 49.641C1.96936 42.8436 7.4797 37.3333 14.2771 37.3333H241.723C248.521 37.3333 254.031 42.8437 254.031 49.641V97.8872C254.031 98.9747 253.149 99.8564 252.062 99.8564H3.93859C2.85101 99.8564 1.96936 98.9747 1.96936 97.8872V49.641Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M29.0463 45.2102C29.0463 40.8599 32.5729 37.3333 36.9232 37.3333H219.077C223.427 37.3333 226.954 40.8599 226.954 45.2102V78.6872C226.954 83.0375 223.427 86.5641 219.077 86.5641H36.9232C32.5729 86.5641 29.0463 83.0375 29.0463 78.6872V45.2102Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path fillRule="evenodd" clipRule="evenodd" d="M224.492 61.9488H31.5078V57.0257H224.492V61.9488Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M17.5236 48H13.1423C13.0405 48 12.9464 48.0543 12.8955 48.1425L10.7048 51.9368C10.6539 52.025 10.6539 52.1336 10.7048 52.2218L12.8955 56.0161C12.9464 56.1043 13.0405 56.1586 13.1423 56.1586H17.5236C17.6254 56.1586 17.7195 56.1043 17.7704 56.0161L19.961 52.2218C20.0119 52.1336 20.0119 52.025 19.961 51.9368L17.7704 48.1425C17.7195 48.0543 17.6254 48 17.5236 48Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M13.9344 54.5064L12.5352 52.083H10.6672C10.6672 52.1322 10.68 52.1814 10.7054 52.2255L12.8961 56.0198C12.9215 56.0639 12.9578 56.0995 13.0004 56.1242L13.9344 54.5064Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M13.9338 49.6581L12.9998 48.0403C12.9572 48.065 12.9209 48.1006 12.8955 48.1447L10.7048 51.939C10.6793 51.9831 10.6666 52.0323 10.6666 52.0815H12.5346L13.9338 49.6581Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M13.9345 49.6594H16.7328L17.6668 48.0417C17.6242 48.0171 17.5752 48.0035 17.5243 48.0035H13.143C13.0921 48.0035 13.0431 48.0171 13.0005 48.0417L13.9345 49.6594Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M16.733 49.6597L18.1322 52.0831H20.0002C20.0002 52.0339 19.9874 51.9847 19.962 51.9407L17.7713 48.1463C17.7459 48.1022 17.7096 48.0666 17.667 48.042L16.733 49.6597Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M16.7327 49.6596H13.9343L12.5352 52.083L13.9343 54.5065H16.7327L18.1318 52.083L16.7327 49.6596Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M241.524 48H237.142C237.041 48 236.946 48.0543 236.896 48.1425L234.705 51.9368C234.654 52.025 234.654 52.1336 234.705 52.2218L236.896 56.0161C236.946 56.1043 237.041 56.1586 237.142 56.1586H241.524C241.626 56.1586 241.72 56.1043 241.771 56.0161L243.961 52.2218C244.012 52.1336 244.012 52.025 243.961 51.9368L241.771 48.1425C241.72 48.0543 241.626 48 241.524 48Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M237.934 54.5064L236.535 52.083H234.667C234.667 52.1322 234.68 52.1814 234.705 52.2255L236.896 56.0198C236.922 56.0639 236.958 56.0995 237 56.1242L237.934 54.5064Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M237.934 49.6581L237 48.0403C236.957 48.065 236.921 48.1006 236.896 48.1447L234.705 51.939C234.679 51.9831 234.667 52.0323 234.667 52.0815H236.535L237.934 49.6581Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M237.935 49.6594H240.733L241.667 48.0417C241.624 48.0171 241.575 48.0035 241.524 48.0035H237.143C237.092 48.0035 237.043 48.0171 237.001 48.0417L237.935 49.6594Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M240.733 49.6597L242.132 52.0831H244C244 52.0339 243.987 51.9847 243.962 51.9407L241.771 48.1463C241.746 48.1022 241.71 48.0666 241.667 48.042L240.733 49.6597Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M240.733 49.6596H237.934L236.535 52.083L237.934 54.5065H240.733L242.132 52.083L240.733 49.6596Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M188.19 89.3333H183.809C183.707 89.3333 183.613 89.3876 183.562 89.4758L181.372 93.2701C181.321 93.3583 181.321 93.4669 181.372 93.5551L183.562 97.3495C183.613 97.4376 183.707 97.492 183.809 97.492H188.19C188.292 97.492 188.386 97.4376 188.437 97.3495L190.628 93.5551C190.679 93.4669 190.679 93.3583 190.628 93.2701L188.437 89.4758C188.386 89.3876 188.292 89.3333 188.19 89.3333Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M184.601 95.8398L183.202 93.4163H181.334C181.334 93.4655 181.347 93.5147 181.372 93.5588L183.563 97.3531C183.588 97.3972 183.624 97.4329 183.667 97.4575L184.601 95.8398Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M184.6 90.9914L183.666 89.3737C183.624 89.3983 183.588 89.4339 183.562 89.478L181.372 93.2723C181.346 93.3164 181.333 93.3656 181.333 93.4148H183.201L184.6 90.9914Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M184.601 90.9928H187.4L188.334 89.375C188.291 89.3504 188.242 89.3369 188.191 89.3369H183.81C183.759 89.3369 183.71 89.3504 183.667 89.375L184.601 90.9928Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M187.4 90.993L188.799 93.4165H190.667C190.667 93.3673 190.654 93.318 190.629 93.274L188.438 89.4796C188.413 89.4355 188.376 89.3999 188.334 89.3753L187.4 90.993Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M187.399 90.9929H184.601L183.202 93.4164L184.601 95.8398H187.399L188.799 93.4164L187.399 90.9929Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M70.8569 89.3333H66.4757C66.3738 89.3333 66.2797 89.3876 66.2288 89.4758L64.0382 93.2701C63.9873 93.3583 63.9873 93.4669 64.0382 93.5551L66.2288 97.3495C66.2797 97.4376 66.3738 97.492 66.4757 97.492H70.8569C70.9588 97.492 71.0529 97.4376 71.1038 97.3495L73.2944 93.5551C73.3453 93.4669 73.3453 93.3583 73.2944 93.2701L71.1038 89.4758C71.0529 89.3876 70.9588 89.3333 70.8569 89.3333Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M67.2678 95.8398L65.8686 93.4163H64.0006C64.0006 93.4655 64.0133 93.5147 64.0388 93.5588L66.2294 97.3531C66.2549 97.3972 66.2911 97.4329 66.3338 97.4575L67.2678 95.8398Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M67.2671 90.9914L66.3332 89.3737C66.2905 89.3983 66.2543 89.4339 66.2288 89.478L64.0382 93.2723C64.0127 93.3164 64 93.3656 64 93.4148H65.868L67.2671 90.9914Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M67.2679 90.9928H70.0662L71.0002 89.375C70.9575 89.3504 70.9086 89.3369 70.8577 89.3369H66.4764C66.4255 89.3369 66.3765 89.3504 66.3339 89.375L67.2679 90.9928Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M70.0664 90.993L71.4656 93.4165H73.3335C73.3335 93.3673 73.3208 93.318 73.2954 93.274L71.1047 89.4796C71.0793 89.4355 71.043 89.3999 71.0004 89.3753L70.0664 90.993Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M70.0661 90.9929H67.2677L65.8685 93.4164L67.2677 95.8398H70.0661L71.4652 93.4164L70.0661 90.9929Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M9.84629 58.6666C26.1599 58.6666 39.3848 71.8915 39.3848 88.2051L39.3848 94.1128C39.3848 96.2879 37.6215 98.0512 35.4463 98.0512L1.96938 98.0512C0.881803 98.0512 0.000150456 97.1696 0.000150409 96.082L0.000148945 62.6051C0.00014885 60.4299 1.76346 58.6666 3.93861 58.6666L9.84629 58.6666Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M12.9338 58.6666C27.2129 58.6666 40.0928 77.5289 36.4923 91.1924C35.6508 94.3849 31.7592 95.3958 29.5841 95.3958C20.7091 95.3958 13.3341 95.3958 0.0161438 95.3958C0.0161438 90.6666 0.0161438 62.9484 0.0161438 62.9484C-0.185268 60.6466 1.62897 58.6666 3.93961 58.6666L12.9338 58.6666Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M246.154 58.6666C229.84 58.6666 216.615 71.8915 216.615 88.2051L216.615 94.1128C216.615 96.2879 218.379 98.0512 220.554 98.0512L254.031 98.0512C255.118 98.0512 256 97.1696 256 96.082L256 62.6051C256 60.4299 254.237 58.6666 252.061 58.6666L246.154 58.6666Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M246.154 58.6666C229.84 58.6666 216.615 71.8915 216.615 88.2051L216.615 93.5625C216.615 95.7291 216.881 95.4035 219.794 95.386C231.104 95.386 240.118 95.3635 246.402 95.386C249.035 95.3954 251.21 93.4733 251.35 90.8432C251.862 81.2608 252.061 61.9286 252.061 58.6666L246.154 58.6666Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <g filter="currentColor" data-sentry-element="g" data-sentry-source-file="ArcadeChestSolidColor.tsx">
      <path d="M150.35 100.84C147.193 100.84 144.639 98.2859 144.639 95.1298V86.5639C144.639 77.1168 136.954 69.4318 127.507 69.4318C118.06 69.4318 110.375 77.1168 110.375 86.5639V95.1298C110.375 98.2866 107.821 100.84 104.664 100.84C101.508 100.84 98.9537 98.2859 98.9537 95.1298V86.5639C98.9537 70.8204 111.764 58.0104 127.507 58.0104C143.251 58.0104 156.061 70.8204 156.061 86.5639V95.1298C156.061 98.2866 153.507 100.84 150.35 100.84Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    </g>
    <path d="M2.00167 100.253C1.98426 100.038 2.15403 99.8536 2.3697 99.8536H253.631C253.846 99.8536 254.016 100.038 253.999 100.253L244.295 220.096C244.233 220.863 243.592 221.454 242.823 221.454H13.1777C12.4082 221.454 11.7677 220.863 11.7056 220.096L2.00167 100.253Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M25.7371 115.244C25.6638 114.382 26.3437 113.642 27.2087 113.642H228.793C229.658 113.642 230.338 114.382 230.265 115.244L222.516 206.321C222.451 207.085 221.811 207.673 221.044 207.673H34.9574C34.1903 207.673 33.5509 207.085 33.4858 206.321L25.7371 115.244Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M70.8569 102.667H66.4756C66.3738 102.667 66.2797 102.721 66.2288 102.809L64.0382 106.603C63.9873 106.692 63.9873 106.8 64.0382 106.889L66.2288 110.683C66.2797 110.771 66.3738 110.825 66.4756 110.825H70.8569C70.9588 110.825 71.0529 110.771 71.1038 110.683L73.2944 106.889C73.3453 106.8 73.3453 106.692 73.2944 106.603L71.1038 102.809C71.0529 102.721 70.9588 102.667 70.8569 102.667Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M67.2677 109.173L65.8686 106.75H64.0006C64.0006 106.799 64.0133 106.848 64.0388 106.892L66.2294 110.687C66.2549 110.731 66.2911 110.766 66.3338 110.791L67.2677 109.173Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M67.2671 104.325L66.3331 102.707C66.2905 102.732 66.2543 102.767 66.2288 102.811L64.0382 106.606C64.0127 106.65 64 106.699 64 106.748H65.868L67.2671 104.325Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M67.2679 104.326H70.0662L71.0002 102.708C70.9575 102.684 70.9086 102.67 70.8577 102.67H66.4764C66.4255 102.67 66.3765 102.684 66.3339 102.708L67.2679 104.326Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M70.0664 104.326L71.4656 106.75H73.3335C73.3335 106.701 73.3208 106.651 73.2954 106.607L71.1047 102.813C71.0793 102.769 71.043 102.733 71.0004 102.709L70.0664 104.326Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M70.066 104.326H67.2677L65.8685 106.75L67.2677 109.173H70.066L71.4652 106.75L70.066 104.326Z" fill="#FFCA14" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M188.19 102.667H183.809C183.707 102.667 183.613 102.721 183.562 102.809L181.371 106.603C181.321 106.692 181.321 106.8 181.371 106.889L183.562 110.683C183.613 110.771 183.707 110.825 183.809 110.825H188.19C188.292 110.825 188.386 110.771 188.437 110.683L190.628 106.889C190.679 106.8 190.679 106.692 190.628 106.603L188.437 102.809C188.386 102.721 188.292 102.667 188.19 102.667Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M184.601 109.173L183.202 106.75H181.334C181.334 106.799 181.347 106.848 181.372 106.892L183.563 110.687C183.588 110.731 183.624 110.766 183.667 110.791L184.601 109.173Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M184.6 104.325L183.666 102.707C183.624 102.732 183.588 102.767 183.562 102.811L181.371 106.606C181.346 106.65 181.333 106.699 181.333 106.748H183.201L184.6 104.325Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M184.601 104.326H187.399L188.333 102.708C188.291 102.684 188.242 102.67 188.191 102.67H183.81C183.759 102.67 183.71 102.684 183.667 102.708L184.601 104.326Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M187.4 104.326L188.799 106.75H190.667C190.667 106.701 190.654 106.651 190.629 106.607L188.438 102.813C188.412 102.769 188.376 102.733 188.334 102.709L187.4 104.326Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M187.399 104.326H184.601L183.202 106.75L184.601 109.173H187.399L188.798 106.75L187.399 104.326Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M238.857 156H234.476C234.374 156 234.28 156.054 234.229 156.143L232.038 159.937C231.987 160.025 231.987 160.134 232.038 160.222L234.229 164.016C234.28 164.104 234.374 164.159 234.476 164.159H238.857C238.959 164.159 239.053 164.104 239.104 164.016L241.294 160.222C241.345 160.134 241.345 160.025 241.294 159.937L239.104 156.143C239.053 156.054 238.959 156 238.857 156Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M235.268 162.506L233.869 160.083H232.001C232.001 160.132 232.013 160.181 232.039 160.226L234.229 164.02C234.255 164.064 234.291 164.1 234.334 164.124L235.268 162.506Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M235.267 157.658L234.333 156.04C234.29 156.065 234.254 156.101 234.229 156.145L232.038 159.939C232.013 159.983 232 160.032 232 160.081H233.868L235.267 157.658Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M235.268 157.659H238.066L239 156.042C238.957 156.017 238.909 156.004 238.858 156.004H234.476C234.425 156.004 234.376 156.017 234.334 156.042L235.268 157.659Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M238.066 157.66L239.465 160.083H241.333C241.333 160.034 241.321 159.985 241.295 159.941L239.105 156.146C239.079 156.102 239.043 156.067 239 156.042L238.066 157.66Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M238.066 157.66H235.268L233.868 160.083L235.268 162.506H238.066L239.465 160.083L238.066 157.66Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M20.1902 156H15.8089C15.7071 156 15.613 156.054 15.5621 156.143L13.3714 159.937C13.3205 160.025 13.3205 160.134 13.3714 160.222L15.5621 164.016C15.613 164.104 15.7071 164.159 15.8089 164.159H20.1902C20.292 164.159 20.3861 164.104 20.437 164.016L22.6277 160.222C22.6786 160.134 22.6786 160.025 22.6277 159.937L20.437 156.143C20.3861 156.054 20.292 156 20.1902 156Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M16.601 162.506L15.2018 160.083H13.3339C13.3339 160.132 13.3466 160.181 13.372 160.226L15.5627 164.02C15.5882 164.064 15.6244 164.1 15.667 164.124L16.601 162.506Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M16.6004 157.658L15.6664 156.04C15.6238 156.065 15.5875 156.101 15.5621 156.145L13.3714 159.939C13.346 159.983 13.3333 160.032 13.3333 160.081H15.2012L16.6004 157.658Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M16.6011 157.659H19.3994L20.3334 156.042C20.2908 156.017 20.2418 156.004 20.1909 156.004H15.8096C15.7587 156.004 15.7097 156.017 15.6671 156.042L16.6011 157.659Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M19.3997 157.66L20.7988 160.083H22.6668C22.6668 160.034 22.6541 159.985 22.6286 159.941L20.438 156.146C20.4125 156.102 20.3763 156.067 20.3336 156.042L19.3997 157.66Z" fill="#fff" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M19.3993 157.66H16.6009L15.2018 160.083L16.6009 162.506H19.3993L20.7985 160.083L19.3993 157.66Z" fill="#fff" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M86.8569 210.667H82.4757C82.3738 210.667 82.2797 210.721 82.2288 210.809L80.0382 214.603C79.9873 214.692 79.9873 214.8 80.0382 214.889L82.2288 218.683C82.2797 218.771 82.3738 218.825 82.4757 218.825H86.8569C86.9588 218.825 87.0529 218.771 87.1038 218.683L89.2944 214.889C89.3453 214.8 89.3453 214.692 89.2944 214.603L87.1038 210.809C87.0529 210.721 86.9588 210.667 86.8569 210.667Z" fill="#fff" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M83.2678 217.173L81.8686 214.75H80.0006C80.0006 214.799 80.0133 214.848 80.0388 214.892L82.2294 218.687C82.2549 218.731 82.2911 218.766 82.3338 218.791L83.2678 217.173Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M83.2671 212.325L82.3332 210.707C82.2905 210.732 82.2543 210.767 82.2288 210.811L80.0382 214.606C80.0127 214.65 80 214.699 80 214.748H81.868L83.2671 212.325Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M83.2679 212.326H86.0662L87.0002 210.708C86.9575 210.684 86.9086 210.67 86.8577 210.67H82.4764C82.4255 210.67 82.3765 210.684 82.3339 210.708L83.2679 212.326Z" fill="#fff" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M86.0664 212.326L87.4656 214.75H89.3335C89.3335 214.701 89.3208 214.651 89.2954 214.607L87.1047 210.813C87.0793 210.769 87.043 210.733 87.0004 210.709L86.0664 212.326Z" fill="#fff" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M86.0661 212.326H83.2677L81.8685 214.75L83.2677 217.173H86.0661L87.4652 214.75L86.0661 212.326Z" fill="#fff" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M172.19 210.667H167.809C167.707 210.667 167.613 210.721 167.562 210.809L165.371 214.603C165.321 214.692 165.321 214.8 165.371 214.889L167.562 218.683C167.613 218.771 167.707 218.825 167.809 218.825H172.19C172.292 218.825 172.386 218.771 172.437 218.683L174.628 214.889C174.679 214.8 174.679 214.692 174.628 214.603L172.437 210.809C172.386 210.721 172.292 210.667 172.19 210.667Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M168.601 217.173L167.202 214.75H165.334C165.334 214.799 165.347 214.848 165.372 214.892L167.563 218.687C167.588 218.731 167.624 218.766 167.667 218.791L168.601 217.173Z" fill="#fff" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M168.6 212.325L167.666 210.707C167.624 210.732 167.588 210.767 167.562 210.811L165.371 214.606C165.346 214.65 165.333 214.699 165.333 214.748H167.201L168.6 212.325Z" fill="#fff" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M168.601 212.326H171.399L172.333 210.708C172.291 210.684 172.242 210.67 172.191 210.67H167.81C167.759 210.67 167.71 210.684 167.667 210.708L168.601 212.326Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M171.4 212.326L172.799 214.75H174.667C174.667 214.701 174.654 214.651 174.629 214.607L172.438 210.813C172.412 210.769 172.376 210.733 172.334 210.709L171.4 212.326Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M171.399 212.326H168.601L167.202 214.75L168.601 217.173H171.399L172.798 214.75L171.399 212.326Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M9.84632 137.433C26.16 137.433 39.3848 124.209 39.3848 107.895L39.3848 101.987C39.3848 99.812 37.6215 98.0487 35.4463 98.0487L2.13587 98.0487C0.985411 98.0487 0.0799387 99.0308 0.173105 100.177L2.90606 133.814C3.0722 135.859 4.78011 137.433 6.83159 137.433L9.84632 137.433Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M39.3848 101.987C39.3848 99.8115 37.6215 98.0482 35.4463 98.0482L2.13587 98.0482C0.98541 98.0482 0.0799385 99.0302 0.173105 100.177L2.90606 133.813C22.1851 137.033 39.3848 120.116 39.3848 101.987Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M246.154 137.433C229.841 137.433 216.616 124.208 216.616 107.895L216.616 101.987C216.616 99.8119 218.379 98.0486 220.554 98.0486L253.865 98.0486C255.015 98.0486 255.921 99.0306 255.827 100.177L253.095 133.814C252.928 135.858 251.22 137.433 249.169 137.433L246.154 137.433Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M216.615 107.895L216.615 101.988C216.615 99.8125 218.379 98.0492 220.554 98.0492L250.776 98.0492C249.867 106.929 248.885 128.807 247.862 132.45C246.838 136.092 237 135.045 232.231 133.721C227.463 132.397 216.366 122.05 216.615 107.895Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M19.6926 182.233C36.0063 182.233 49.2311 195.458 49.2311 211.772L49.2311 217.679C49.2311 219.854 47.4678 221.618 45.2926 221.618L11.6509 221.618C10.6299 221.618 9.77817 220.837 9.68917 219.82L6.77496 186.515C6.57355 184.213 8.38779 182.233 10.6984 182.233L19.6926 182.233Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M19.6941 182.233C40.215 182.233 44.3359 197.956 44.3359 215.882C44.3359 218.057 42.4156 218.83 40.2404 218.83C34.0877 219.215 24.7529 218.83 9.57527 218.83L6.77642 186.515C6.57501 184.213 8.38926 182.233 10.6999 182.233L19.6941 182.233Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M236.308 182.233C219.994 182.233 206.769 195.458 206.769 211.772L206.769 217.679C206.769 219.854 208.533 221.618 210.708 221.618L244.35 221.618C245.371 221.618 246.222 220.837 246.311 219.82L249.226 186.515C249.427 184.213 247.613 182.233 245.302 182.233L236.308 182.233Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <path d="M236.307 182.233C217.957 182.602 205.177 197.091 206.733 216.543C206.733 218.752 208.725 219.032 212.926 219.032C222.392 219.102 232.118 219.265 239.377 219.265C240.398 219.265 241.249 218.484 241.338 217.467C241.98 210.137 242.681 201.29 244.353 182.233L236.307 182.233Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <line x1="31.5078" y1="177.177" x2="224.492" y2="177.177" stroke="url(#paint3_radial)" strokeWidth="0.923077" data-sentry-element="line" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <line x1="31.5078" y1="137.792" x2="224.492" y2="137.792" stroke="url(#paint4_radial)" strokeWidth="0.923077" data-sentry-element="line" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <g filter="url(#filter1_di)" data-sentry-element="g" data-sentry-source-file="ArcadeChestSolidColor.tsx">
      <path d="M150.696 92.472H105.01C101.855 92.472 99.2988 95.0286 99.2988 98.1827V109.604C99.2988 125.374 112.083 138.158 127.852 138.158C143.622 138.158 156.406 125.374 156.406 109.604V98.1827C156.407 95.0286 153.85 92.472 150.696 92.472Z" fill="#FFF482" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    </g>
    <path d="M136.9 117.657L137.083 117.55L137.083 117.338L137.083 107L137.083 106.788L136.9 106.681L128.038 101.511L127.852 101.403L127.666 101.511L118.804 106.681L118.621 106.788L118.621 107L118.621 117.338L118.621 117.55L118.804 117.657L127.666 122.826L127.852 122.935L128.038 122.826L136.9 117.657Z" fill="#6F5F39" stroke="url(#paint5_linear)" strokeWidth="0.738462" data-sentry-element="path" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
    <defs data-sentry-element="defs" data-sentry-source-file="ArcadeChestSolidColor.tsx">
      <filter id="filter0_di" x="98.5845" y="58.0104" width="57.8455" height="43.5685" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB" data-sentry-element="filter" data-sentry-source-file="ArcadeChestSolidColor.tsx">
        <feFlood floodOpacity="0" result="BackgroundImageFix" data-sentry-element="feFlood" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" data-sentry-element="feColorMatrix" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feOffset dy="0.369231" data-sentry-element="feOffset" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feGaussianBlur stdDeviation="0.184615" data-sentry-element="feGaussianBlur" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" data-sentry-element="feColorMatrix" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" data-sentry-element="feBlend" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" data-sentry-element="feBlend" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" data-sentry-element="feColorMatrix" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feOffset dx="-0.923077" dy="-0.496615" data-sentry-element="feOffset" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" data-sentry-element="feComposite" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.760784 0 0 0 0 0.411765 0 0 0 1 0" data-sentry-element="feColorMatrix" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" data-sentry-element="feBlend" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
      </filter>
      <filter id="filter1_di" x="98.9296" y="92.472" width="57.8455" height="46.4241" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB" data-sentry-element="filter" data-sentry-source-file="ArcadeChestSolidColor.tsx">
        <feFlood floodOpacity="0" result="BackgroundImageFix" data-sentry-element="feFlood" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" data-sentry-element="feColorMatrix" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feOffset dy="0.369231" data-sentry-element="feOffset" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feGaussianBlur stdDeviation="0.184615" data-sentry-element="feGaussianBlur" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" data-sentry-element="feColorMatrix" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" data-sentry-element="feBlend" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" data-sentry-element="feBlend" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" data-sentry-element="feColorMatrix" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feOffset dx="-0.923077" dy="-0.496615" data-sentry-element="feOffset" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" data-sentry-element="feComposite" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.760784 0 0 0 0 0.411765 0 0 0 1 0" data-sentry-element="feColorMatrix" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" data-sentry-element="feBlend" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
      </filter>
      <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.508 121.518) rotate(-90) scale(100.923 431.726)" data-sentry-element="radialGradient" data-sentry-source-file="ArcadeChestSolidColor.tsx">
        <stop stopColor="#8B5040" data-sentry-element="stop" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <stop offset="1" data-sentry-element="stop" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
      </radialGradient>
      <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(129.969 40.6178) rotate(-179.998) scale(111.015 471445)" data-sentry-element="radialGradient" data-sentry-source-file="ArcadeChestSolidColor.tsx">
        <stop stopColor="#6E3F33" data-sentry-element="stop" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <stop offset="1" stopColor="#6E3F33" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
      </radialGradient>
      <linearGradient id="paint2_linear" x1="127.507" y1="58.0104" x2="127.507" y2="115.117" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="ArcadeChestSolidColor.tsx">
        <stop stopColor="#FFF482" data-sentry-element="stop" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <stop offset="1" stopColor="#FFBE82" data-sentry-element="stop" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
      </linearGradient>
      <radialGradient id="paint3_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(129.969 173.64) rotate(-180) scale(111.015 88396)" data-sentry-element="radialGradient" data-sentry-source-file="ArcadeChestSolidColor.tsx">
        <stop stopColor="#6E3F33" data-sentry-element="stop" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <stop offset="1" stopColor="#6E3F33" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
      </radialGradient>
      <radialGradient id="paint4_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(129.969 134.255) rotate(-180) scale(111.015 88396)" data-sentry-element="radialGradient" data-sentry-source-file="ArcadeChestSolidColor.tsx">
        <stop stopColor="#6E3F33" data-sentry-element="stop" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <stop offset="1" stopColor="#6E3F33" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
      </radialGradient>
      <linearGradient id="paint5_linear" x1="136.714" y1="112.169" x2="118.99" y2="112.169" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="ArcadeChestSolidColor.tsx">
        <stop stopColor="#FFC269" data-sentry-element="stop" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
        <stop offset="1" stopColor="#FFC269" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="ArcadeChestSolidColor.tsx" />
      </linearGradient>
    </defs>
  </Icon>;
ArcadeChestSolidColor.displayName = 'ArcadeChestSolidColorIcon';
export default ArcadeChestSolidColor;